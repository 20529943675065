import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../constants";
import { AdminService } from "../../services";

const PublicFooter = () => {
  const [ukNationalNumber, setUKNationalNumber] = useState("");
  const [ukInterNationalNumber, setUKInterNationalNumber] = useState("");
  const [irelandInterNationalNumber, setIrelandInterNationalNumber] =
    useState("");
  const [irelandNationalNumber, setIrelandNationalNumber] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");

  const parse = require("html-react-parser");

  useEffect(async () => {
    const response = await AdminService.getAllContents();
    if (response) {
      response.map((item, index) => {
        if (item.page_name === "gewardz_Phone_Ireland_National") {
          setIrelandNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_Ireland_International") {
          setIrelandInterNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_UK_International") {
          setUKInterNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_UK_National") {
          setUKNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardzEmail") {
          setEmail(item.page_content);
        }
        if (item.page_name === "gewardz_contact") {
          setContact(item.page_content);
        }
      });
    } else {
    }
  }, []);


  return (
    <React.Fragment>
      <div>
        <section className="Program">
          <h2>Gewardz Assist Program</h2>
          <div className="email-icon-gewardz">
            <a
              href={`mailto:${
                parse(email).props && parse(email).props.children
              }`}
            >
              {" "}
              <i className="fa-solid fa-envelope"></i>
              {parse(email)}
            </a>
          </div>
          <div className="container">
            {/* <div className="row">
              <div className="col-md-6 frey">
                <div className="assit-contact">
                  <h6>Ireland:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-phone"></i> National :<a href={`tel:${parse(irelandNationalNumber).props && parse(irelandNationalNumber).props.children}`}>{parse(irelandNationalNumber)}</a>
                    </p>
                    <p>International : <a href={`tel:${parse(irelandInterNationalNumber).props && parse(irelandInterNationalNumber).props.children}`}>{parse(irelandInterNationalNumber)}</a></p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ftry">
                <div className="assit-contact">
                  <h6>UK:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-phone"></i> National : <a href={`tel:${parse(ukNationalNumber).props && parse(ukNationalNumber).props.children}`}>{parse(ukNationalNumber)}</a>
                    </p>
                    <p> International : <a href={`tel:${parse(ukInterNationalNumber).props && parse(ukInterNationalNumber).props.children}`}>{parse(ukInterNationalNumber)}</a> </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-12">
              <div className="text-mb">
                <p>
                  {" "}
                  <i className="fa-solid fa-phone"></i> Contact Us :{" "}
                  <a href={`tel:${parse(contact)  && parse(contact).props && parse(contact).props.children}}`}>{parse(contact)}</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="inr-ftr">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="gew-new">
                  <p>
                    {" "}
                    © Copyright{" "}
                    <a href="https://gewardz.com/"> Gewardz Media Ltd. </a> All
                    Rights Reserved
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="privacy">
                  <Link to={Routes.PRIVACYANDPOLICY}>Privacy Policy </Link>
                  <Link to={Routes.FAQ}> FAQ’s</Link>
                  <Link to={Routes.CONTACT}> Contact</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default PublicFooter;
