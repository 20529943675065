import React from "react";

import PublicHeader from "./common/PublicHeader";
import PublicFooter from "./common/PublicFooter";

const PublicLayout = (props) => {
  return (
    <React.Fragment>
      <PublicHeader />
      {props.children}
      <PublicFooter />
    </React.Fragment>
  );
};

export default PublicLayout;
