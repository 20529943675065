import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../constants";
import { JWT } from "../shared";
import { Circles } from "react-loader-spinner";
import { AdminService } from "../services";

const Home = () => {
  const history = useHistory();
  const [fb1title, setFB1Title] = useState("");
  const [fb1content, setFB1Content] = useState("");
  const [fb2title, setFB2Title] = useState("");
  const [fb2content, setFB2Content] = useState("");
  const [fb3title, setFB3Title] = useState("");
  const [fb3content, setFB3Content] = useState("");
  const [fb4title, setFB4Title] = useState("");
  const [fb4content, setFB4Content] = useState("");
  const [fb5title, setFB5Title] = useState("");
  const [fb5content, setFB5Content] = useState("");
  const [fb6title, setFB6Title] = useState("");
  const [fb6content, setFB6Content] = useState("");
  const [fb7content, setFB7Content] = useState("");
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState("");
  const [ukNationalNumber, setUKNationalNumber] = useState("");
  const [ukInterNationalNumber, setUKInterNationalNumber] = useState("");
  const [irelandInterNationalNumber, setIrelandInterNationalNumber] =
    useState("");
  const [irelandNationalNumber, setIrelandNationalNumber] = useState("");
  const [email, setEmail] = useState("");

  const countersRef = useRef([]);

  const parse = require("html-react-parser");

  useEffect(() => {
    const selectHeader = document.querySelector(".home-header");
    if (selectHeader) {
      document.addEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
    }
  }, []);

  const isValidToken = JWT.isValidToken();

  const renderLogin = () => {
    history.push(Routes.REGISTER);
  };

  useEffect(async () => {
    if(window.location.href=="http://bookings.zumohealth.co.uk/" || window.location.href=="https://bookings.zumohealth.co.uk/"){
		history.push(Routes.LOGIN);
	}
    setLoading(true);
    const response = await AdminService.getAllContents();
    if (response) {
      setLoading(false);
      response.map((item, index) => {
        if (item.page_name === "frontendBlock1") {
          setFB1Title(item.title);
          setFB1Content(item.page_content);
        }
        if (item.page_name === "frontendBlock2") {
          setFB2Title(item.title);
          setFB2Content(item.page_content);
        }
        if (item.page_name === "frontendBlock3") {
          setFB3Title(item.title);
          setFB3Content(item.page_content);
        }
        if (item.page_name === "frontendBlock4") {
          setFB4Title(item.title);
          setFB4Content(item.page_content);
        }
        if (item.page_name === "frontendBlock5") {
          setFB5Title(item.title);
          setFB5Content(item.page_content);
        }
        if (item.page_name === "frontendBlock6") {
          setFB6Title(item.title);
          setFB6Content(item.page_content);
        }
        if (item.page_name === "frontendBlock7") {
          setFB7Content(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_Ireland_National") {
          setIrelandNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_Ireland_International") {
          setIrelandInterNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_UK_International") {
          setUKInterNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardz_Phone_UK_National") {
          setUKNationalNumber(item.page_content);
        }
        if (item.page_name === "gewardzEmail") {
          setEmail(item.page_content);
        }
        if (item.page_name === "gewardz_contact") {
          setContact(item.page_content);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  // console.log( contact && parse(contact).props.children,"contacttttttttttttttttttttt")
  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* <div>This is Home page</div>
     <Link to={Routes.LOGIN}>Sign In</Link>
     <Link to={Routes.REGISTER}>Sign Up</Link> */}
      <section className="home-header fixed-top">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-5">
              <div className="home-logo">
                <Link to={Routes.DEFAULT}>
                  <img src="assets/img/gewardz-white-logo.svg" alt="logo" />
                </Link>
                {/* <img src="assets/img/logo-home-b.png" class="b-logo" alt="logo"> */}
              </div>
            </div>
            {!isValidToken && (
              <div className="col-md-6 col-7">
                <div className="login-btn">
                  <Link to={Routes.LOGIN}>Login</Link>
                </div>
              </div>
            )}
            {isValidToken && (
              <div className="col-md-6 col-7">
                <div className="login-btn">
                  <Link to={Routes.DASHBOARD}>Dashboard</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="container pr pl">
          <div className="row">
            <div className="col-md-6 hftr">
              <div className="hero-banner-content">
                {fb1title && <h1>{parse(fb1title)}</h1>}
               {fb1content && <p>{parse(fb1content)}</p> }
                <button onClick={renderLogin}>
                  Join Now{" "}
                  <img
                    src="assets/img/arrow.svg"
                    className="img-fluid"
                    alt="arrow"
                  />
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner-img">
                <img
                  src="assets/img/banner-img.png"
                  alt="Banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="benifits">
        <div className="health-benifits mb-5">
          <h2>Gewardz Assist Program Benefits</h2>
        </div>
        <div className="container pr pl">
          <div className="row">
            <div className="col-xl-11 col-lg-11  col-md-12">
              <div className="benifits-pargraph">
               {fb2title && <h3>{parse(fb2title)}</h3>}
              {fb2content &&<p>{parse(fb2content)}</p>}
              </div>
            </div>
            <div className="col-md-4 dr-app">
              <div className="new-appointment-lg">
                <img
                  src="assets/img/new-appoitment.jpg"
                  className="img-fluid"
                  alt="appointment"
                />
              </div>
            </div>
          </div>
          {/* bootom sec */}
          <div className="row bottom-left">
            <div className="col-md-4 dr-app">
              <div className="new-appointment-lg">
                <img
                  src="assets/img/wellness.jpg"
                  className="img-fluid"
                  alt="wellness"
                />
              </div>
            </div>
            <div className=" col-xl-11 col-lg-11  col-md-12 right-text">
              <div className="benifits-pargraph">
               {fb3title && <h3>{parse(fb3title)}</h3> }
              {fb3content && <p>{parse(fb3content)}</p>}
              </div>
            </div>
          </div>
          {/* bootom sec */}
          <div className="row">
            <div className=" col-xl-11 col-lg-11  col-md-12 ">
              <div className="benifits-pargraph">
               {fb4title && <h3>{parse(fb4title)}</h3>}
              {fb4content && <p>{parse(fb4content)}</p> }
              </div>
            </div>
            <div className="col-md-4 dr-app">
              <div className="new-appointment-lg">
                <img
                  src="assets/img/Specialis.jpg"
                  className="img-fluid"
                  alt="specialist"
                />
              </div>
            </div>
          </div>
          {/* bootom sec */}
          <div className="row bottom-left">
            <div className="col-md-4 dr-app">
              <div className="new-appointment-lg">
                <img
                  src="assets/img/managerial-support.jpg"
                  className="img-fluid"
                  alt="Managerial-Support"
                />
              </div>
            </div>
            <div className=" col-xl-11 col-lg-11  col-md-12 right-text">
              <div className="benifits-pargraph">
               {fb5title && <h3>{parse(fb5title)}</h3>}
              {fb5content &&<p>{parse(fb5content)}</p>}
              </div>
            </div>
          </div>
          {/* bootom sec */}
        </div>
      </section>

      <section className="our-experts" id="counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xxl-6 col-md-12">
              <div className="human-expertes">
                <h6>Our Expertise</h6>
               {fb6title && <h2>{parse(fb6title)}</h2>}
                {fb6content && <p>{parse(fb6content)} </p> }
              </div>
            </div>

            <div className="col-lg-6 col-xxl-6 col-md-12">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="counter-new">
                    <h2
                      className="counter"
                      ref={(el) => (countersRef.current[0] = el)}
                    >
                      {" "}
                      35m{" "}
                    </h2>
                    <p>lives covered</p>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6">
                  <div className="counter-new">
                    <h2
                      className="counter"
                      ref={(el) => (countersRef.current[0] = el)}
                    >
                      {" "}
                      95%+{" "}
                    </h2>
                    <p>satisfied patients</p>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6 col-sm-6">
                  <div className="counter-new">
                    <h2
                      className="counter"
                      ref={(el) => (countersRef.current[0] = el)}
                    >
                      1000+
                    </h2>

                    <p>businesses served</p>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6">
                  <div className="counter-new">
                    <h2
                      className="counter"
                      ref={(el) => (countersRef.current[0] = el)}
                    >
                      4100+
                    </h2>

                    <p>Clinicians within the network</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-clients">
        <div className="health-benifits  mb-xxl-mb-5 mb-lg-5 mb-md-5 mb-1">
          <h2 className="who">Who we serve</h2>
          <p>SOME OF OUR CLIENTS</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="item-logo">
                <img
                  src="assets/img/ribbon-logo.svg"
                  className="img-fluid"
                  alt="ribbon-logo"
                />
              </div>
            </div>

            <div className="col">
              <div className="item-logo ">
                <img
                  src="assets/img/royal.png"
                  className="img-fluid"
                  alt="royal"
                />
              </div>
            </div>

            <div className="col">
              <div className="item-logo">
                <img
                  src="assets/img/sucess.png"
                  className="img-fluid"
                  alt="travel"
                />
              </div>
            </div>

            <div className="col">
              <div className="item-logo royal">
                <img
                  src="assets/img/2tt.png"
                  className="img-fluid"
                  alt="travel"
                />
              </div>
            </div>

            <div className="col rsz">
              <div className="item-logo golf">
                <img
                  src="assets/img/golf.png"
                  className="img-fluid"
                  alt="travel"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="join-now">
        <div className="container">
          <p>{parse(fb7content)}</p>
          <button onClick={renderLogin}>Join Now</button>
        </div>
      </section>

      <section className="mission">
        <div className="health-benifits">
          <h2 className="fret">Our mission is to</h2>
        </div>
        <h3>"level the playing field"</h3>
        <p>
          by providing the very best products and services to the people and
          businesses that need them most.
        </p>
      </section>

      <div>
        {/* <section className="Program">
          <h2>Gewardz Assist Program</h2>
          <div className="email-icon-gewardz">
          <a href="mailto:sales@gewardz.com "> <i className="fa-solid fa-envelope"></i> sales@gewardz.com </a>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 frey">
                <div className="assit-contact">
                  <h6>Ireland:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-phone"></i> National :<a href={`tel:01 576 9818`}> 01 576 9818 </a> 
                    </p>
                    <p>International : <a href={`tel:+353 1 576 9818`}> +353 1 576 9818 </a></p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ftry">
                <div className="assit-contact">
                  <h6>UK:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-phone"></i> National : <a href={`tel:0800 331 7933`}> 0800 331 7933 </a>
                    </p>
                    <p>International : <a href={`tel:0044 20 8050 9851`}>0044 20 8050 9851</a> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="Program">
          <h2>Gewardz Assist Program</h2>
          <div className="email-icon-gewardz">
            <a
              href={`mailto:${
                parse(email).props && parse(email).props.children
              }`}
            >
              {" "}
              <i className="fa-solid fa-envelope"></i>
              {parse(email)}
            </a>
          </div>
          <div className="container">
            {/* <div className="row">
              <div className="col-md-6 frey">
                <div className="assit-contact">
                  <h6>Ireland:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-pho
                      ne"></i> National :<a href={`tel:${parse(irelandNationalNumber).props && parse(irelandNationalNumber).props.children}`}>{parse(irelandNationalNumber)}</a>
                    </p>
                    <p>International : <a href={`tel:${parse(irelandInterNationalNumber).props && parse(irelandInterNationalNumber).props.children}`}>{parse(irelandInterNationalNumber)}</a></p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ftry">
                <div className="assit-contact">
                  <h6>UK:</h6>
                  <div className="number-country">
                    <p>
                      {" "}
                      <i className="fa-solid fa-phone"></i> National : <a href={`tel:${parse(ukNationalNumber).props && parse(ukNationalNumber).props.children}`}>{parse(ukNationalNumber)}</a>
                    </p>
                    <p> International : <a href={`tel:${parse(ukInterNationalNumber).props && parse(ukInterNationalNumber).props.children}`}>{parse(ukInterNationalNumber)}</a> </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="text-mb">
                <p>
                  {" "}
                  <i className="fa-solid fa-phone"></i> Contact Us :{" "}
                  <a href={`tel:${parse(contact)  && parse(contact).props && parse(contact).props.children}}`}>{parse(contact)}</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="inr-ftr">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="gew-new">
                  <p>
                    {" "}
                    © Copyright{" "}
                    <a href="https://gewardz.com/"> Gewardz Media Ltd. </a> All
                    Rights Reserved
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="privacy">
                  <Link to={Routes.PRIVACYANDPOLICY}>Privacy Policy </Link>
                  <Link to={Routes.FAQ}> FAQ’s</Link>
                  <Link to={Routes.CONTACT}> Contact</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Home;
