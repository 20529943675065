import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { AdminService } from "../../services";
import { Routes } from "../../constants";

const ChangePassword = () => {
  const [cpassword, setCPassword] = useState("");
  const [npassword, setNPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [cpassError, setCPassError] = useState();
  const [npassError, setNPassError] = useState();
  const [rpassError, setRPassError] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const email = localStorage.getItem("email");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCurrentPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setCPassError("Current password is required");
    } else {
      setCPassError("");
    }
    setCPassword(val.trim());
  };
  const handleNewPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();

    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;

    if (val === "") {
      setNPassError("Password is required");
    } else if (!passRegex.test(val)) {
      setNPassError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
    } else {
      setNPassError("");
    }

    setNPassword(val);
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();

    if (val === "") {
      //repeat password validation
      setRPassError("Confirm Password is required");
    } else if (val !== npassword) {
      setRPassError("Password does not Match");
    } else {
      setRPassError("");
    }
    setRPassword(val);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;

    if (cpassword === "") {
      setCPassError("Current Password is required");
      valid = false;
    } else {
      setCPassError("");
    }
    if (npassword === "") {
      setNPassError("New Password is required");
      valid = false;
    } else if (!passRegex.test(npassword)) {
      setNPassError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
      valid = false;
    } else {
      setNPassError("");
    }
    if (rpassword !== npassword) {
      setRPassError("Passwords do not match");
      valid = false;
    } else {
      setRPassError("");
    }

    if (valid) {
      setError("");
      // Handle change password Logic here
      try {
        setLoading(true);
        const response = await AdminService.ChangePassword({
          email: email,
          current_password: cpassword,
          new_password: npassword,
        });
        if (response.status === true) {
          setLoading(false);
          toast.success("Password changed successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push(Routes.DASHBOARD);
        } else {
          toast.error("Something Went Wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {/* <div
        className="tab-pane"
        id="password"
        role="tabpanel"
        aria-labelledby="change-password"
      >
        <div className="pagetitle">
          <h1>Change Password</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active">Change Password</li>
            </ol>
            <div className="edit-button">
              <button className="btn btn-primary w-100" type="submit">
                Edit
              </button>
            </div>
          </nav>
        </div>
        <div className="card ">
          <div className=" form-wrapper card-body">
            <form
              className="row gx-3 justify-content-start needs-validation account-login"
              action="sign-in.html"
              style={{ display: "none" }}
            >
              <div className="col-md-6 form-group mb-2">
                <label htmlFor="yourUsername" className="form-label">
                  Current password
                </label>
                <div className="input-group">
                  <span className="input-group-text" id>
                    <i className="fa-solid fa-lock" />
                  </span>
                  <input
                    type="text"
                    name="Current password"
                    className="form-control"
                    id
                  />
                </div>
              </div>
              <div className="col-md-6 form-group mb-2">
                <label htmlFor="yourUsername" className="form-label">
                  New password
                </label>
                <div className="input-group">
                  <span className="input-group-text" id>
                    <i className="fa-solid fa-lock" />
                  </span>
                  <input
                    type="text"
                    name="New password"
                    className="form-control"
                    id
                  />
                </div>
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="yourUsername" className="form-label">
                  confirm password
                </label>
                <div className="input-group">
                  <span className="input-group-text" id>
                    <i className="fa-solid fa-lock" />
                  </span>
                  <input
                    type="Password"
                    name="Password"
                    className="form-control"
                    id
                  />
                </div>
              </div>
            </form>
            <div className="row mb-4">
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 label username">
                    {" "}
                    <i className="fa-solid fa-lock" />{" "}
                    <label>Current password </label>
                  </div>
                  <div className="col-lg-6 col-md-6">*********</div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 label username">
                    {" "}
                    <i className="fa-solid fa-envelope" />{" "}
                    <label> New password</label>
                  </div>
                  <div className="col-lg-6 col-md-6">345635545</div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 label username">
                    <i className="fa-solid fa-id-badge" />
                    <label>confirm password </label>
                  </div>
                  <div className="col-lg-6 col-md-6">12345678</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section className="change-password-new">
        {loading && (
          <div className="spinner">
            <div className="loader">
              {" "}
              <Circles
                height="110"
                width="110"
                color="#FFF"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        )}
        <div className="container">
          <section className="section register  d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-0 pb-3">
                        <h5 className="card-title text-center pb-0 ">
                          Change Password
                        </h5>
                      </div>
                      <form
                        onSubmit={handleSubmit}
                        className="row g-3 needs-validation account-login"
                      >
                        <div className="col-12">
                          <label htmlFor="cpassword" className="form-label">
                            Current Password
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-key" />
                            </span>
                            <input
                              name="cpassword"
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="Current Password"
                              onChange={handleCurrentPasswordChange}
                            />
                          </div>
                          {cpassError && (
                            <span className="error">{cpassError}</span>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="npassword" className="form-label">
                            New Password
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-key" />
                            </span>
                            <input
                              name="npassword"
                              className="form-control"
                              id="password"
                              type={showPassword ? "text" : "password"}
                              placeholder="New Password"
                              onChange={handleNewPasswordChange}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary eye-splash"
                              onClick={toggleShowPassword}
                            >
                              {showPassword ? (
                                <i className="fa-solid fa-eye-slash" />
                              ) : (
                                <i className="fa-solid fa-eye" />
                              )}
                            </button>
                          </div>
                          {npassError && (
                            <span className="error">{npassError}</span>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="rpassword" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-key" />
                            </span>
                            <input
                              name="rpassword"
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="Confirm Password"
                              onChange={handleConfirmPasswordChange}
                            />
                          </div>
                          {rpassError && (
                            <span className="error">{rpassError}</span>
                          )}
                        </div>
                        {error && (
                          <div className="col-xs-12 col-md-12 col-lg-12">
                            <div className="alert alert-danger">{error}</div>
                          </div>
                        )}
                        <div className="col-12 login mb-2">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ChangePassword;
