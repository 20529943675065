import axios from "axios";


export const getClientSecret = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/payment-intent`, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const ChangePassword = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/change-password`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUserDetails = async (user_id) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-detail/${user_id}`);
    if (response.status === 200) {
      localStorage.setItem("Subscription_status", response.data.subscription);
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const getPatientDetails = async (user_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getpatient/${user_id}`);
    if (response.status === 200) {
      //localStorage.setItem("Subscription_status", response.data.subscription);
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const processPayemnt = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/process-payment`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const listApppointments = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/listappointments`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const holdAppointment = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/appointmentonhold`,
      data,
      { headers: { "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    // console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const createBooking = async (data, user_id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/booking/create/user/${user_id}`,
      data,
      { headers: { "Content-Type": "application/json" } }
    );
    // console.log(response, "response inside AuthService");
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const CancelAppointment = async (userId, aptId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/cancel/user/${userId}/booking/${aptId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getContents = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contents`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getBookingDetails = async (user_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/booking/details/user/${user_id}`,
      { headers: { "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCoupounDetails = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/applycoupon`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createpatient = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/createpatient`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 201) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const createDependentPatient = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/createdependent`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 201) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const CancelSubscription = async (id) => { 
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/cancel-subscription/${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200 || response.status === 204) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};


export const getAllContents = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contents`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status) {
      return response.data.message;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createEnquiry = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/enquiry`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const getAllfaqs = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/faq`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePatientGPDetails = async (pId,data) => { 
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/creategpdetails/${pId}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const updateDependentGPDetails = async (dependentId,data) => { 
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/createdependentgp/${dependentId}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const updatePatientPharmacyDetails = async (pId,data) => { 
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/createpharmadetails/${pId}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const updateDependentPharmacyDetails = async (dependentId,data) => { 
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/createdependentpharmacy/${dependentId}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};


export const getPatientList = async (user_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getPatientRecordOfUser/${user_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};