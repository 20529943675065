import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../constants";

const DeclineSubscription = () => {
  return (
    <React.Fragment>
      <section className="premium-plan">
        <div className="container">
          <div className="row gx-3 justify-content-center">
            <div className="col-xxl-6 col-xl-5 col-md-6 mb-4 mb-md-0">
              <div className="box featured purchased">
                <h2>Subscription Failed</h2>
                <i className="fa-solid fa-times" />
                <p>
                  We're sorry, but there was an error processing your
                  subscription.
                </p>
                <div className="btn-wrap">
                  <Link to={Routes.PRODUCTSUBSCRIPTION}>Go Subscribe</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default DeclineSubscription;
