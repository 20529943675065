import React from "react";

export default function Error() {
  return (
    <div>
      <div style={{ marginTop: "200px", height: "50vh" }}>
        <center>
          <h1>404</h1>
          <h2>Page you requested not Found!</h2>
        </center>
      </div>
    </div>
  );
}