import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./../App.css";
import { Routes } from "../constants";
import { AdminService } from "../services";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { injectModels } from "../redux/injectModels";

const ProductSubscription = (props) => {
  const history = useHistory();

  const [userDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  const isSubs = localStorage.getItem("Subscription_status");
  const isTrial = localStorage.getItem("trial");
  const userId = localStorage.getItem("user_id");
  const isValidToken = localStorage.getItem("accessToken");

  const getUserDetails = async () => {
    const response = await AdminService.getUserDetails(userId);
    setUserDetails(response);
    setCountry(response.country);
  };

  useEffect(async () => {
    getUserDetails();
  }, [isSubs]);

  console.log(isTrial);
  // const handleCancel = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await AdminService.CancelSubscription(userId);

  //     if (response.status === true) {
  //       setLoading(false);
  //       window.location.reload();
  //     } else {
  //       toast.error(response.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       setLoading(false);
  //     }
  //   } catch {}
  // };

  const handleCancel = async () => {
    try {
      const result = await Swal.fire({
        title: "Cancel Subscription",
        text: "Are you sure you want to cancel your subscription?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (result.isConfirmed) {
        setLoading(true);
        const response = await AdminService.CancelSubscription(userId);

        if (response.status === true) {
          setLoading(false);
          await props.auth.logout();
          localStorage.clear();
          window.location.reload();
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      }
    } catch (err) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    const checkoutUrl = localStorage.getItem("checkoutURL");
    window.location.replace(checkoutUrl);
  };
  // const handleRedirtectReject = () => {
  //   toast.error("Please login first!!", {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // };

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="premium-checkout">
        <div className="container">
          <div className="row ju">
            <div className="col-md-12">
              <h2 className="uph2 mb-4">Subscription</h2>
            </div>
            <div className=" col-xxl-7 col-xl-7 col-lg-7  col-md-10">
              <div className="heckout-right">
                {userId ? (
                  <React.Fragment>
                    <div className="payment-login payment-logut pb-3">
                      <h5 className="pt-0">
                        {" "}
                        <i className="fa-solid fa-check"></i> Sign Up {""}
                        <div className="email-showing">{userDetails.email}</div>
                      </h5>

                      <div className="logout-checkout">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            props.auth.logout();
                            history.push(Routes.PRODUCTSUBSCRIPTION);
                            localStorage.clear();
                          }}
                          className="dropdown-item "
                        >
                          <i className="fa-solid fa-arrow-right-from-bracket" />
                          <span>Logout</span>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="payment-login ">
                      <h5 className="pt-0 xxl-pb-3 lg-pb-3 md-pb-3 pb-1">
                        1. Sign Up
                      </h5>
                      <p>
                        To purchase this plan and use its benefits in the
                        future, log in to your account or sign up.
                      </p>
                    </div>
                    <div className="signup-checkout">
                      <Link to={Routes.REGISTER}>Sign Up</Link>
                      <Link to={Routes.LOGIN}>Login</Link>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="order-summary">
                <h6>Order summary</h6>
                <div className="membership-plan-checkout">
                  <p>Plan</p>
                  <p>Members subscription</p>
                </div>
                <div className="membership-plan-checkout">
                  <p>Duration</p>
                  <p>Until canceled</p>
                </div>

                {country && (country === "EU" || country === "IR") ? (
                  <React.Fragment>
                    <div className="membership-plan-checkout mt-3">
                      <p>Subtotal</p>
                      <p>€19.99</p>
                    </div>
                    {/* <div className="membership-plan-checkout value-money">
                      <p>VAT (23 %)</p>
                      <p>€3.45</p>
                    </div> */}
                    <div className="total-value">
                      <p>Total</p>
                      <p>€19.99</p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="membership-plan-checkout mt-3">
                      <p>Subtotal</p>
                      <p>£19.99</p>
                    </div>
                    {/* <div className="membership-plan-checkout value-money">
                      <p>VAT (20 %)</p>
                      <p>£2.40</p>
                    </div> */}
                    <div className="total-value">
                      <p>Total</p>
                      <p>£19.99</p>
                    </div>
                  </React.Fragment>
                )}

                <span>every month</span>
              </div>
              {isValidToken ? (
                (isSubs == 0 && isTrial == 0) || isTrial == undefined ? (
                  <button
                    onClick={handleRedirect}
                    className="org-big-btn btn-bx-shadow w-100 mt-3"
                  >
                    {" "}
                    <span>Buy Subscription</span>{" "}
                  </button>
                ) : (
                  // <button
                  //   onClick={handleCancel}
                  //   className="org-big-btn btn-bx-shadow w-100 mt-3"
                  // >
                  //   {" "}
                  //   <span>Cancel Subscription</span>{" "}
                  // </button>
                
                    <a
                      href="mailto:support@gewardz.com?subject=Subscription Cancellation Request"
                      className="btn-bx-shadow w-100 mt-3 subs-cancel"
                    >
                      Please note that I wish to cancel my subscription
                    </a>
              
                )
              ) : (
                <Link to={Routes.REGISTER}>
                  <button className="org-big-btn btn-bx-shadow w-100 mt-3">
                    <span>Buy Subscription</span>{" "}
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(ProductSubscription);
