import React, { useState, useEffect } from "react";
import { AuthService } from "../../services";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";

const ResetPassword = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [rtoken, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [uriId, setUriId] = useState("");
  const [orgID, setOrgID] = useState("");

  const [uriImage, setUriImage] = useState("");

  const location = useLocation();
  const history = useHistory();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const uriData = queryParams.get("uri");
  const tokenData = queryParams.get("token")

  useEffect(async () => {
    if (uriData) {
      setLoading(true);
      const orgDetails = await props.auth.getOrgById(uriData,tokenData);
      if (orgDetails.message.length > 0) {
        let id = orgDetails.message[0].id;
        // setUriId(`/login?uri=${uriData}&token=${tokenData}`);
        setUriImage(orgDetails.message && orgDetails.message[0].image);
        setOrgID("" + orgDetails.message[0].id + "");
        localStorage.setItem("uriToken",tokenData)
        localStorage.setItem("uripath", uriData);
        localStorage.setItem("uriLogo", orgDetails.message[0].image);
        if (orgDetails) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      localStorage.removeItem("uriLogo");
      localStorage.removeItem("uripath");
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    setToken(token);
  }, [location.search]);

  const handleNewPasswordChange = (e) => {
    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setNewPasswordError("New password is required");
    } else if (!passRegex.test(val)) {
      //password validation
      setNewPasswordError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
    } else {
      setNewPasswordError("");
    }
    setNewPassword(val.trim());
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();

    if (val === "") {
      //repeat password validation
      setConfirmPasswordError("Confirm Password is required");
    } else if (val !== newPassword) {
      setConfirmPasswordError("Password does not Match");
    } else {
      setConfirmPasswordError("");
    }
    setConfirmPassword(val);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;
    let valid = true;

    if (newPassword === "") {
      setNewPasswordError("New Password is required");
      valid = false;
    } else if (!passRegex.test(newPassword)) {
      setNewPasswordError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
      valid = false;
    } else {
      setNewPasswordError("");
    }

    if (confirmPassword === "") {
      setNewPasswordError("Confirm Password is required");
      valid = false;
    } else {
      setNewPasswordError("");
    }

    if (!valid) {
      return;
    } else {
      // Password validation passed, continue with reset password logic
      // ...
      const data = {
        password: newPassword,
        password_confirmation: confirmPassword,
        token: rtoken,
        tokenVal: tokenData ? tokenData : "",
      };
      setLoading(true);
      AuthService.ResetPassword(data)

        .then((response) => {
          if (response.success === true) {
            setLoading(false);
            toast.success("password changed successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(Routes.LOGIN);
          } else {
            toast.error("Something went Wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("An error has occured", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <React.Fragment>
      <main>
        <section className="login-bg-image">
          {loading && (
            <div className="spinner">
              <div className="loader">
                {" "}
                <Circles
                  height="110"
                  width="110"
                  color="#FFF"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          )}
          <div className="container">
            <section className="section register  d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-5 col-md-8  d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <Link
                        to={
                          uriImage
                            ? `/reset-password?uri=${uriData}&token=${tokenData}`
                            : Routes.DEFAULT
                        }
                        className="logo d-flex align-items-center w-auto"
                      >
                        {}
                        {loading ? (
                          <div>
                            <img
                              src="/assets/img/placeholder.png"
                              alt="Loading"
                            />
                          </div>
                        ) : (
                          <img
                            src={uriImage || "/assets/img/logo.png"}
                            alt=""
                          />
                        )}
                      </Link>
                    </div>

                    {/* End Logo */}
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-2 pb-3">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Reset Password
                          </h5>
                        </div>
                        <form
                          className="row g-3 needs-validation account-login"
                          method="post"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <label htmlFor="newPassword" className="form-label">
                              New Password
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-lock" />
                              </span>
                              <input
                                name="newPassword"
                                className="form-control"
                                id="newPassword"
                                type={showPassword ? "text" : "password"}
                                onChange={handleNewPasswordChange}
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary eye-splash"
                                onClick={toggleShowPassword}
                              >
                                {showPassword ? (
                                  <i className="fa-solid fa-eye-slash" />
                                ) : (
                                  <i className="fa-solid fa-eye" />
                                )}
                              </button>
                            </div>
                            {newPasswordError && (
                              <span className="error">{newPasswordError}</span>
                            )}
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="confirmPassword"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-lock" />
                              </span>
                              <input
                                name="confirmPassword"
                                className="form-control"
                                id="confirmPassword"
                                type="password"
                                onChange={handleConfirmPasswordChange}
                              />
                            </div>
                            {confirmPasswordError && (
                              <span className="error">
                                {confirmPasswordError}
                              </span>
                            )}
                          </div>

                          <div className="col-12 login mb-5">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </div>
                        </form>
                        <div className="back-login">
                          <Link to={Routes.LOGIN}>Back to login</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(ResetPassword);
