import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthLayout } from "./layouts";
import { Routes } from "./constants";
import { injectModels } from "./redux/injectModels";

const CustomAuthRoute = ({ component: Component, ...rest }) => {
  const isSubs = localStorage.getItem("Subscription_status");
  const istrial = localStorage.getItem("trial")

  
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {isSubs == 1 || istrial == 1 ? (
              <Redirect to={Routes.DASHBOARD} />
            ) : (
              <AuthLayout>
                <Component {...props} />
              </AuthLayout>
            )}
            {/* <AuthLayout><Component {...props} /></AuthLayout> */}
          </React.Fragment>
        );
      }}
    />
  );
};

export default injectModels(["auth"])(CustomAuthRoute);
