import {login, logout,getOrgById, getUpcomingBookingDetails} from './actions';
import store from '../../store';

const model = {
    userLogin: (data)=>{
        return store.dispatch(login(data));
        //login(data, store.dispatch)
    },
    logout: () => {
       return store.dispatch(logout());
    },
    getOrgById: (uri,tokenData,referrerUrl) => {
        return store.dispatch(getOrgById(uri,tokenData,referrerUrl));
     },
     getUpcomingBookingDetails: (user_id) =>{
        return store.dispatch(getUpcomingBookingDetails(user_id))
     },
}

export default model;