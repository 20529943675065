import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { injectModels } from "../../redux/injectModels";
import { Routes } from "../../constants";
import Logo from '../../logo.svg'

const Header = (props) => {
  const history = useHistory();
  const [intervalId, setIntervalId] = useState(null);
  const [startInterval, setStartInterval] = useState(true);
  const username = localStorage.getItem("email");
  const uriLogo = localStorage.getItem("uriLogo");
  const orgId = localStorage.getItem("orgId");
  const orgURI = localStorage.getItem("uripath");
  // const token = localStorage.getItem("uriToken");

  // const organisation_id = localStorage.getItem("organisation_id");

  // const getOrgDetails  =async () => {
  //   const orgainsationID =   await props.auth.getOrgById(organisation_id)
  // }
  // const tokenVal = localStorage.getItem("tokenVal");
  // const sessionVal = localStorage.getItem("sessionVal");

  // const valueAfterColon = tokenVal && tokenVal.split("::")[1];
  // const urlGetDate = new Date(valueAfterColon);

  // const checkSessionValidity = async () => {
  //   const sessionStartTime = new Date(
  //     localStorage.getItem("session_startTime")
  //   );
  //   const sessionLogOutTime = new Date(
  //     localStorage.getItem("session_logOutTime")
  //   );
  //   const currentTime = new Date();

  //   if (currentTime < sessionStartTime || currentTime > sessionLogOutTime) {
  //     console.log("User logged out.");
  //     await props.auth.logout();
  //     history.push(Routes.LOGIN);
  //   } else if (
  //      sessionVal && sessionVal.getDate() ||
  //     urlGetDate.getDate() !== currentTime.getDate()
  //   ) {
  //     await props.auth.logout();
  //     history.push(Routes.LOGIN);
  //     console.log(
  //       "User logged out because the URL date is not the same as the current date."
  //     );
  //   } else {
  //     console.log("User is logged in and session is valid.");
  //   }
  // };

  // useEffect(() => {
  //   var tokenData = localStorage.getItem("tokenData");
  //   if (tokenData && tokenData.length > 0) {
  //     const id = setInterval(checkSessionValidity, 1000);
  //     setIntervalId(id);
  //     return () => clearInterval(id);
  //     //return () => clearInterval(interval);
  //   }
  // }, []);




  return (
    <React.Fragment>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between menu-burger">
          <Link
            to={Routes.DASHBOARD}
            className="logo d-flex align-items-center logo-new"
          >
            {uriLogo ? (
              <img src={uriLogo} alt="" />
            ) : (
              <img src="assets/img/gewardz-black-logo.svg" alt="" />
            )}
          </Link>
          {/* <i className="fa-solid fa-bars toggle-sidebar-btn" /> */}
        </div>
        {/* End Logo */}
        {/* <div className="search-bar">
    <form className="search-form d-flex align-items-center" method="POST" action="#">
      <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
      <button type="submit" title="Search"><i className="fa-solid fa-magnifying-glass" /></button>
    </form>
  </div> */}
        {/* End Search Bar */}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-lg-none">
              <Link className="nav-link nav-icon search-bar-toggle " to="#">
                <i className="bi bi-search" />
              </Link>
            </li>
            {/* End Search Icon*/}
            <li className="nav-item dropdown pe-3">
              <Link
                className="nav-link nav-profile d-flex align-items-center pe-0"
                to="#"
                data-bs-toggle="dropdown"
              >
                {/* <img
                  src="assets/img/profile-img.png"
                  alt="Profile"
                  className="rounded-circle"
                /> */}
                <span className=" d-md-block dropdown-toggle ps-2">
                  {/* {username} */}
                  <i className="fa-solid fa-user"></i>
                </span>
              </Link>
              {/* End Profile Iamge Icon */}
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{username}</h6>
                </li>
                {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}
                {/* <li>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <i className="fa-regular fa-user" />
              <span>My Profile</span>
            </Link>
          </li> */}

                {/* <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to={Routes.DASHBOARD}
                  >
                    <i className="fa-solid fa-gauge" />
                    <span>Dashboard</span>
                  </Link>
                </li> */}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {orgId ? (
                  ""
                ) : (
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to={Routes.PRODUCTSUBSCRIPTION}
                    >
                      <i className="fa-solid fa-user" />
                      <span>My Subscription</span>
                    </Link>
                  </li>
                )}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to={Routes.CHANGEPASSWORD}
                  >
                    <i className="fa-solid fa-gear" />
                    <span>Change Password</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* <li>
            <Link className="dropdown-item d-flex align-items-center" to="#">
              <i className="fa-regular fa-circle-question" />
              <span>Need Help?</span>
            </Link>
          </li> */}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      props.auth.logout();
                      if (uriLogo) { 
                        history.push({
                          pathname: Routes.LOGIN,
                          search: "?uri=" + orgURI,
                        });
                      } else {
                        history.push(Routes.LOGIN);
                      }
                      localStorage.clear();
                    }}
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="fa-solid fa-arrow-right-from-bracket" />
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
              {/* End Profile Dropdown Items */}
            </li>
            {/* End Profile Nav */}
          </ul>
        </nav>
        {/* End Icons Navigation */}
      </header>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(Header);
