import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import { AdminService } from "../services";

const TermsAndCondition = () => {
  const [data, setData] = useState("");
  const [title,setTitle] = useState("")
  const [loading, setLoading] = useState(false);

  const parse = require('html-react-parser');

  useEffect(async () => {
    setLoading(true);
    const response = await AdminService.getAllContents();
    if (response) {
      setLoading(false);
      response.map((item, index) => {
        if (item.page_name === "tearm&conditions") {
          setData(item.page_content);
          setTitle(item.title)
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="team  section-green">
        <div className="container">
          <React.Fragment>
            <div className="row">
              <div className="col-md-12 to-serve">
                <h2 className="new-heading mb-4">
                  <span>{parse(title)}</span>
                </h2>
              </div>
            </div>
            <div className="row tnc">
              <div className="col-md-12">
                {/* <h4>Introduction</h4> */}
                <p>{parse(data)}</p>
              </div>
            </div>
          </React.Fragment>
        
        </div>
      </section>
    </React.Fragment>
  );
};

export default TermsAndCondition;
