import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import { AdminService } from "../services";

const Faq = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  const parse = require('html-react-parser');

  useEffect(async () => {
    setLoading(true);
    const response = await AdminService.getAllfaqs();
    setLoading(false);
    setData(response.message);
  }, []);

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="faq">
        <div className="container">
          <div className="row gx-3 justify-content-center">
            <div className="col-md-12">
              <div className="faq-list">
                <h2>FAQs</h2>
                <ul>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <li>
                          <a
                            data-bs-toggle="collapse"
                            className="collapse collapsed"
                            data-bs-target={`#faq-list-${item.id}`}
                          >
                            {parse(item.title)}
                            <i className="fa-solid fa-angle-down icon-show" />
                            <i className="fa-solid fa-chevron-up icon-close" />
                          </a>
                          <div
                            id={`faq-list-${item.id}`}
                            className="collapse"
                            data-bs-parent=".faq-list"
                          >
                            <div className="faq-ans">
                              <p>{parse(item.description)}</p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Faq;
