import { Route, Redirect } from "react-router-dom";
import { PublicLayout } from "./layouts";
import { Routes } from "./constants";
import React from "react";

const CustomPublicRoute = ({ component: Component, ...rest }) => {
  // const isSubs = localStorage.getItem("Subscription_status")

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {/* {isSubs === 1 ? <PublicLayout><Component {...props} /></PublicLayout> :  <Redirect to={Routes.PRODUCTSUBSCRIPTION} />} */}
            {
              <PublicLayout>
                <Component {...props} />
              </PublicLayout>
            }
          </React.Fragment>
        );
      }}
    />
  );
};

export default CustomPublicRoute;
