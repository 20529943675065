import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { AdminService } from "../../services";
import { Circles } from "react-loader-spinner";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const PatientList = () => {
  const history = useHistory();

  const [patientData, setPatientData] = useState([]);
  const [dependentData, setDependentData] = useState([]);
  const [loading, setLoading] = useState("");
  const [showDependentModal, setShowDependentModal] = useState(false);
  const [dependentPatients, setDependentPatients] = useState([]);
  const [selectedDependentId, setSelectedDependentID] = useState("");

  const userId = localStorage.getItem("user_id");

  // const getPatientList = async () => {
  //   setLoading(true);
  //   const response = await AdminService.getPatientList(userId);
  //   console.log(response,"response")
  //   setPatientData(response.message);
  //   setLoading(false);
  // };
  const getPatientList = async () => {
    setLoading(true);
    const response = await AdminService.getPatientList(userId);
    const patientDataArray = response.message; // Assuming patient data is an array of objects
    setLoading(false);

    setPatientData(patientDataArray);
  };
  const openConfirmationDialog = () => {
    Swal.fire({
      title: "Are you under the age of 16 ?",
      text: "Please confirm your age to continue.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      // Note the use of `async` here
      if (result.isConfirmed) {
        try {
          const dependentPatientsData = await AdminService.getPatientList(
            userId
          );

          setDependentPatients(dependentPatientsData.message);
          setShowDependentModal(true);
        } catch (error) {
          console.error("Error fetching dependent patients:", error);
        }
      } else {
        // Handle the case when the user declines (optional)
      }
    });
  };

  const handleSelectDependent = (p_id) => {
    setSelectedDependentID(p_id);
    setShowDependentModal(false);
    history.push(`${Routes.DEPENDENTFORM}/${p_id}`);
  };

  useEffect(() => {
    getPatientList();
  }, []);

  const handleGoBack = () => {
    history.push(Routes.DASHBOARD);
  };
  const handleCloseModal = () => {
    setShowDependentModal(false);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <Modal
        show={showDependentModal}
        contentlabel="Create User Modal"
        className=" modal-lg"
      >
        <div className="modal-dep">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Select the guardian who will be present from the list below.
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body ">
              {dependentPatients.map((patient, index) => (
                // console.log(patient,"patietnlist")
                <div className="dep-list" key={patient.id}>
                  {/* Name:<p>{patient.firstName}</p>
                      Email:<p>{patient.email}</p> */}
                  <ul>
                    <li>
                      <span>{index + 1}</span>.&nbsp;
                      <span>Patient Name:</span>{" "}
                      {patient.firstName+ " " + patient.lastName}
                    </li>
                    <li>
                      <span>Email: </span> {patient.email}
                    </li>

                    <button onClick={() => handleSelectDependent(patient.id)}>
                      select
                    </button>
                  </ul>
                  {/* Name: <label>  {patient.firstName} </label> Email:<p>({patient.email})</p> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>

      <section>
        <div className="container">
          <div className="row">
            <button className="back-button me-3" onClick={handleGoBack}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className="col-md-12">
              <div className="hrtyt">
                <div className="add-patinet-btn">
                  <Link to={Routes.PATIENTFORM}>
                    <i className="fa-solid fa-user-plus" />
                    Add Patient
                  </Link>
                </div>
                {patientData && (
                  <div className="add-patinet-de">
                    <button onClick={openConfirmationDialog}>
                      <i className="fa-solid fa-user-plus" />

                      <span>Add Dependent</span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-12">
                <h2 className="uph2 mb-4">Patient List</h2>
              </div>

              {patientData && patientData.length > 0 ? (
                <React.Fragment>
                  {patientData &&
                    patientData.map((item, index) => {
                      return (
                        <div key={index}>
                          {/* <div className="patient-list-lft mb-3"> */}
                          <div className={`patient-list-lft mb-3 ${item.dependents.length === 0 ? '' : 'has-dependents'}`}>
                            <div className="row ">
                              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="detail-pt">
                                  <div className="name-fht">
                                    <i className="fa-solid fa-user" />
                                    <h3>
                                      {item.firstName} {item.lastName}
                                    </h3>
                                    {item.gender === "Male" ? (
                                      <div className="name-fht">
                                        <i className="fa-solid fa-mars" />
                                        <h6>Male</h6>
                                      </div>
                                    ) : (
                                      <div className="name-fht">
                                        <i className="fa-solid fa-venus" />
                                        <h6>Female</h6>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className="detail-pt">
                                  <div className="name-fht">
                                    <i className="fa-solid fa-envelope" />
                                    <h3>{item.email}</h3>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-2 col-lg-2 col-md-4 col-6">
                                <div className="detail-pt">
                                  <div className="name-fht">
                                    <i className="fa-solid fa-phone" />
                                    <h3>{item.contactno}</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-2 col-lg-2 col-md-4 col-6">
                                <div className="detail-pt">
                                  <div className="name-fht">
                                    <i className="fa-solid fa-globe" />
                                    <h3>{item.country}</h3>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-2 col-lg-2 col-md-4 col-12">
                                <div className="new-btn-patient">
                                  <Link to={`${Routes.BOOKING}/${item.id}/0`}>
                                    {" "}
                                    <i className="fa-solid fa-calendar-plus"></i>{" "}
                                    <span>Book Now</span>{" "}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item.dependents && item.dependents.length > 0 && (
                            <React.Fragment>
                              <div className="depty-patient">
                                <h4>Dependent Patients</h4>
                                {item.dependents.map((dependent, depIndex) => (
                                  <div key={depIndex}>
                                    <div className="row g-0">
                                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                        <div className="detail-pt">
                                          <div className="name-fht">
                                            <i className="fa-solid fa-user" />
                                            <h3>
                                              {dependent.firstName}{" "}
                                              {dependent.lastName}
                                            </h3>
                                            {dependent.gender === "Male" ? (
                                              <div className="name-fht">
                                                <i className="fa-solid fa-mars" />
                                                <h6>Male</h6>
                                              </div>
                                            ) : (
                                              <div className="name-fht">
                                                <i className="fa-solid fa-venus" />
                                                <h6>Female</h6>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-2 col-lg-2 col-md-4 col-12">
                                        <div className="new-btn-patient">
                                          <Link
                                            to={`${Routes.BOOKING}/${item.id}/${dependent.id}`}
                                          >
                                            {" "}
                                            <i className="fa-solid fa-calendar-plus"></i>{" "}
                                            <span>Book Now</span>{" "}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="patient-list-lft mb-3">
                    <div className="row ">
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="detail-pt">
                          <label>Please Create Patient Record First</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PatientList;
