import React, { useState, useEffect } from "react";
import { AuthService } from "../../services";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [orgID, setOrgID] = useState("");
  const [uriId, setUriId] = useState("");
  const [uriImage, setUriImage] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const uriData = queryParams.get("uri");
  const tokenData = queryParams.get("token")

  useEffect(async () => {
    if (uriData) {
      setLoading(true);
      const orgDetails = await props.auth.getOrgById(uriData,tokenData);
      if (orgDetails.message.length > 0) {
        let id = orgDetails.message[0].id;
        setUriId(`/login?uri=${uriData}&token=${tokenData}`);
        setUriImage(orgDetails.message && orgDetails.message[0].image);
        setOrgID("" + orgDetails.message[0].id + "");
        localStorage.setItem("uriToken",tokenData);
        localStorage.setItem("uripath", uriData);
        localStorage.setItem("uriLogo", orgDetails.message[0].image);
        if (orgDetails) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      localStorage.removeItem("uriLogo");
      localStorage.removeItem("uripath");
    }
  }, []);

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Email is not valid");
    } else {
      setEmailError("");
    }

    setEmail(val);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (email === "") {
      setEmailError("Email is Required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Not a valid email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!valid) {
      return;
    } else {
      setEmailError("");
      setLoading(true);
      const OrgUrl = uriId.slice(uriId.indexOf("?uri="));
      const data = {
        email: email,
        orgId: orgID,
        tokenVal: tokenData ? tokenData : "",
      };
      AuthService.ForgotPassword(data)

        .then((response) => {
          if (response.success === true) {
            setLoading(false);
            setSuccessMessage(response.message);
            toast.success("Email sent successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(uriId ? uriId : Routes.LOGIN);
          } else {
            setEmailError(response.message);
            toast.error("User not found", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setEmailError("An error has occured!");
        });
    }
  };

  return (
    <React.Fragment>
      <main>
        <section className="login-bg-image">
          {loading && (
            <div className="spinner">
              <div className="loader">
                {" "}
                <Circles
                  height="110"
                  width="110"
                  color="#FFF"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          )}
          <div className="container">
            <section className="section register  d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-5 col-md-8  d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <Link
                        to={
                          uriImage
                            ? `/forgot-password?uri=${uriData}&token=${tokenData}`
                            : Routes.DEFAULT
                        }
                        className="logo d-flex align-items-center w-auto"
                      >
                        {}
                        {loading ? (
                          <div>
                            <img
                              src="/assets/img/placeholder.png"
                              alt="Loading"
                            />
                          </div>
                        ) : (
                          <img
                            src={uriImage || "/assets/img/logo.png"}
                            alt=""
                          />
                        )}
                      </Link>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-2 pb-3">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Forgot Password
                          </h5>
                        </div>
                        <form
                          className="row g-3 needs-validation account-login"
                          method="post"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <label
                              htmlFor="yourUsername"
                              className="form-label"
                            >
                              Email
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                name="Email"
                                className="form-control"
                                id="Email"
                                type="email"
                                onChange={handleEmailChange}
                              />
                            </div>
                            {emailError && (
                              <span className="error">{emailError}</span>
                            )}
                            {successMessage && (
                              <div className="col-xs-12 col-md-12 col-lg-12">
                                <div className="alert alert-success">
                                  {successMessage}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12 login mb-5">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </div>
                        </form>
                        <div className="back-login">
                          <Link to={uriId ? uriId : Routes.LOGIN}>
                            Back to login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(ForgotPassword);
