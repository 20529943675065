export const routes = {
    DEFAULT: "/",
    LOGIN:"/login",
    REGISTER:'/register',
    FORGOT_PASSWORD:'/forgot-password',
    RESET_PASSWORD:'/reset-password',
    DASHBOARD:"/dashboard",
    PROFILE:"/profile",
    CHANGEPASSWORD:"/change-password",
    BOOKING:"/appointment-booking",
    SUBSCRIPTION:"/subscription-dashbaord",
    PRODUCTSUBSCRIPTION:"/product-subscription",
    CHECKOUT:"/checkout",
    CONTACT:'/contact',
    FAQ:'/faq',
    BOOKINGFORM:'/create-booking',
    PATIENTFORM:'/create-patient',
    PATIENTLIST:'/all-patients',
    DEPENDENTFORM:'/add-dependent',
    PATIENTUPDATEDFORM:'/update-patient-details',
    TERMSANDCONDITION:'/terms-and-condition',
    PRIVACYANDPOLICY:'/privacy-policy',
    THANKYOUPAGE:'/thank-you',
    FAILEDSUBSCRIPTION:'/failed-subscription',
    ERROR:"*"
};