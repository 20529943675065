import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../constants";


const ThankYou = () => {
  
  useEffect(() => {
    localStorage.setItem("trial", 1);
  },);

  return (
    <React.Fragment>
      <section className="premium-plan">
        <div className="container">
          <div className="row gx-3 justify-content-center">
            <div className="col-xxl-6 col-xl-5 col-md-6 mb-4 mb-md-0">
              <div className="box featured purchased thanks-subscription">
                <h2>Thank You !!</h2>
                <i className="fa-solid fa-check" />
                <p>
                  Thanks for subscribing. you should receive <br /> a
                  confirmation email soon
                </p>
                <div className="btn-wrap">
                  <Link to={Routes.DASHBOARD}>Go Dashboard</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ThankYou;
