import React, { useState } from "react";
import { AdminService } from "../../services";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Routes } from "../../constants";
import { Circles } from "react-loader-spinner";
import { injectModels } from "../../redux/injectModels";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const PatientForm = () => {
  const history = useHistory();
  const minDate = new Date("1900-01-01"); 

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [postcode, setPostcode] = useState("");

  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dobError, setDobError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [contactError, setContactError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [address2Error, setAddress2Error] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsAcceptedError, setTermsAcceptedError] = useState("");
  const [privacyError, setPrivacyError] = useState("");

  const user_id = localStorage.getItem("user_id");

  const handleFirstNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setFNameError("Name is required");
    } else if (val.trim().length < 2) {
      setFNameError("The first name field must be at least 2 characters.");
    } else {
      setFNameError("");
    }
    setFname(val.trimStart());
  };

  const handleLastNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setLNameError("Name is required");
    } else if (val.trim().length < 2) {
      setLNameError("The last name field must be at least 2 characters.");
    } else {
      setLNameError("");
    }
    setLname(val.trimStart());
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid email");
    } else {
      setEmailError("");
    }

    setEmail(val);
  };

  // const handleDOBChange = (date) => {
  //   if (date) {
  //     const today = new Date();
  //     if (date > today) {
  //       setDobError("Please choose a date prior to the current date");
  //     } else {
  //       setDobError("");
  //       setDob(date);
  //     }
  //   } else {
  //     setDobError("Date of birth is required");
  //   }
  // };

  const handleDOBChange = (date) => { 
    if (!date) {
      setDob("");
      setDobError("Date of Birth is required");
    } else {
      const today = new Date();
      const minDate = new Date();
      minDate.setFullYear(today.getFullYear() - 16); // Set minDate to 16 years ago

      if (date > today) {
        setDobError("Please choose a date prior to the current date");
      } else if (date > minDate) {
        setDobError("You must be at least 16 years old");
      } else {
        setDobError("");
        setDob(date);
      }
    }
  };

  const handleGenderChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setGenderError("Gender is required");
    } else {
      setGenderError("");
    }

    setGender(val);
  };

  const handleContactChange = (e) => {
    const regex = /^\+?[0-9]{8,15}$/;
    const val = e.target.value;
    const sanitizedVal = val.replace(/^[+0]+/, ""); // Remove leading plus sign or zeros

    if (val.trim() === "") {
      setContactError("");
    } else if (!regex.test(sanitizedVal)) {
      setContactError("Enter a valid phone number (8 to 15 digits)");
    } else {
      setContactError("");
    }

    setContactNo(val.trim());
  };

  const handleAddress1Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAddress1Error("Address1 is required");
    } else if (val.trim().length < 2) {
      setAddress1Error("The address1 field must be at least 2 characters.");
    } else {
      setAddress1Error("");
    }
    setAddress1(val.trimStart());
  };

  const handlePostcodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const regex = /^[a-zA-Z0-9\s]{6,9}$/;

    if (val.trim() === "") {
      setPostcodeError("Postcode is required");
    } else if (!regex.test(val)) {
      setPostcodeError(
        "Please enter a valid postcode (6 to 9 alphanumeric characters and spaces)"
      );
    } else {
      setPostcodeError("");
    }

    setPostcode(val);
  };

  const handleAddress2Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAddress2Error("");
    } else if (val.trim().length < 2) {
      setAddress2Error("Address 2 must be at least 2 characters");
    } else {
      setAddress2Error("");
    }
    setAddress2(val.trimStart());
  };

  const handleCityChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setCityError("City is required");
    } else if (val.trim().length < 2) {
      setCityError("The city field must be at least 2 characters.");
    } else {
      setCityError("");
    }
    setCity(val.trimStart());
  };

  const handleCountryChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length === 17) return false;
    if (val.trim() === "") {
      setCountryError("Country name is required");
    } else {
      setCountryError("");
    }
    setCountry(val.trim());
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleCheckbox1Change = () => {
    setPrivacyAccepted(!privacyAccepted);
  };
  const handleGoBack = () => {
    history.push(Routes.DASHBOARD);
  };
  const handleSubmit = async (event) => {
    const regex = /^[a-zA-Z0-9\s]{6,9}$/; // Regular expression to match numbers with a maximum length of 6 characters
    event.preventDefault();

    let valid = true;
    if (fname === "") {
      setFNameError("First Name is required");
      valid = false;
    } else if (fname.trim().length < 2) {
      setFNameError("The first name field must be at least 2 characters.");
      valid = false;
    } else {
      //submit response handle
      setFNameError("");
    }
    if (lname === "") {
      setLNameError("Last Name is required");
      valid = false;
    } else if (lname.trim().length < 2) {
      setLNameError("The last name field must be at least 2 characters.");
      valid = false;
    } else {
      //submit response handle
      setLNameError("");
    }
    if (gender === "") {
      setGenderError("Gender is required");
      valid = false;
    } else {
      //submit response handle
      setGenderError("");
    }
    if (country === "") {
      setCountryError("Country Name is required");
      valid = false;
    } else {
      //submit response handle
      setCountryError("");
    }
    if (city === "") {
      setCityError("City is required");
      valid = false;
    } else if (city.trim().length < 2) {
      setCityError("The city field must be at least 2 characters.");
      valid = false;
    } else {
      setCityError("");
    }

    if (dob === "") {
      setDobError("Date of Birth is required");
      valid = false;
    } else {
      const selectedDate = new Date(dob);
      const today = new Date();

      if (selectedDate > today) {
        setDobError("Please choose a date prior to the current date");
        valid = false;
      } else {
        setDobError("");
      }
    }
    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email");
      valid = false;
    } else {
      setEmailError("");
    }

    if (contactNo.trim() === "") {
      setContactError("Contact number is required");
      valid = false;
    } else {
      const regex = /^\+?[0-9]{8,15}$/;
      const sanitizedVal = contactNo.replace(/^[+0]+/, ""); // Remove leading plus sign or zeros

      if (sanitizedVal.length < 8 || sanitizedVal.length > 15) {
        setContactError("Enter a valid phone number (8 to 15 digits)");
        valid = false;
      } else if (!regex.test(sanitizedVal)) {
        setContactError("Enter a valid phone number (8 to 15 digits)");
        valid = false;
      } else {
        setContactError("");
      }
    }
    if (address1 === "") {
      setAddress1Error("Address is required");
      valid = false;
    } else if (address1.trim().length < 2) {
      setAddress1Error("The address1 field must be at least 2 characters.");
      valid = false;
    } else {
      setAddress1Error("");
    }
    if (postcode === "") {
      setPostcodeError("Post Code is required");
      valid = false;
    } else if (!regex.test(postcode)) {
      setPostcodeError("Please enter a valid postcode");
      valid = false;
    } else {
      setPostcodeError("");
    }
    if (!termsAccepted) {
      setTermsAcceptedError("You must agree before submitting.");
      valid = false;
    } else {
      setTermsAcceptedError("");
    }

    if (!privacyAccepted) {
      setPrivacyError("You must agree before submitting.");
      valid = false;
    } else {
      setPrivacyError("");
    }

    if (!valid) {
      setLoading(false);
      return;
    } else {
      setError("");
      setLoading(true);
      let x = moment(dob).format("DD-MM-YYYY");
      const data = {
        firstName: fname,
        lastName: lname,
        gender: gender,
        DOB: x,
        user_id: user_id,
        address1: address1,
        address2: address2,
        city: city,
        post_code: postcode,
        email: email,
        contactno: contactNo,
        country: country,
      };
      try {
        const response = await AdminService.createpatient(data);
        if (response.message) {
          toast.success("Patient data saved successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
          localStorage.removeItem("patientId");
          localStorage.setItem("patientId", response.patient.id);
          localStorage.setItem("patientCountry", country);
          history.push(`${Routes.PATIENTUPDATEDFORM}/${response.patient.id}`);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error, "error");
        if (error.message === "Request failed with status code 422") {
          setLoading(false);
          toast.error("This email has already been taken.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Something went wrong!!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <main>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}

      <section className="patientform">
        <div className="container">
          <div className="row justify-content-center">
            <button className="back-button me-3" onClick={handleGoBack}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className="col-xl-7 col-lg-7 col-md-10  d-flex flex-column align-items-center justify-content-center">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-1 pb-3">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Create Patient Record
                    </h5>
                  </div>
                  <form
                    className="needs-validation account-login"
                    onSubmit={handleSubmit}
                  >
                    <div className="row ">
                      <div className=" col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="yourUsername" className="form-label">
                          First Name
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-regular fa-user" />{" "}
                          </span>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="yourFirstName"
                            value={fname}
							maxLength={12}
                            onChange={handleFirstNameChange}
                          />
                        </div>
                        {fnameError && (
                          <span className="error">{fnameError}</span>
                        )}
                      </div>
                      <div className=" col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="yourUsername" className="form-label">
                          Last Name
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-regular fa-user" />{" "}
                          </span>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="yourLastName"
                            value={lname}
                            onChange={handleLastNameChange}
							maxLength={15}
                          />
                        </div>
                        {lnameError && (
                          <span className="error">{lnameError}</span>
                        )}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-envelope" />
                          </span>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>
                        {/* <div className="">
                        <Link to="" onClick={handleVerifyEmail}>Verify Email</Link>
                        </div > */}
                        {emailError && (
                          <span className="error">{emailError}</span>
                        )}
                      </div>

                      <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="contact" className="form-label">
                          Contact Number
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-mobile-screen-button" />
                          </span>
                          <input
                            type="text"
                            name="contact"
                            className="form-control"
                            id="contact"
                            value={contactNo}
                            onChange={handleContactChange}
                            placeholder="+44123456789"
                          />
                        </div>
                        {contactError && (
                          <span className="error">{contactError}</span>
                        )}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="dob" className="form-label">
                          Date of Birth
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-calendar" />
                          </span>
                          {/* <DatePicker
                              selected={dob}
                              onChange={handleDOBChange}
                              className="form-control"
                              id="dob"
                              placeholderText="Select a date"
                              dateFormat="dd-MM-yyyy"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              dropdownMode="select"
                              autoComplete="off"
                              isClearable
                              maxDate={new Date()} // This restricts selecting future dates
                              // This adds a clear button
                              disabledKeyboardNavigation // This disables keyboard input
                              onKeyDown={(e) => e.preventDefault()} // This prevents typing in the field
                              onFocus={(e) => e.preventDefault()} // This prevents the input field from getting focus
                            /> */}
                          <DatePicker
                            selected={dob}
                            onChange={handleDOBChange}
                            className="form-control"
                            id="dob"
                            placeholderText="Select a date"
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            dropdownMode="select"
                            autoComplete="off"
                            isClearable
                            maxDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 16
                                )
                              )
                            } // Current date minus 16 years
                             minDate={minDate}
                            disabledKeyboardNavigation // This disables keyboard input
                            onKeyDown={(e) => e.preventDefault()} // This prevents typing in the field
                            onFocus={(e) => e.preventDefault()} // This prevents the input field from getting focus
                          />
                        </div>
                        {dobError && <span className="error">{dobError}</span>}
                      </div>

                      <div className="col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="gender" className="form-label">
                          Gender Assigned at Birth
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-genderless" />
                          </span>
                          <select
                            name="gender"
                            className="form-select"
                            id="gender"
                            value={gender}
                            onChange={handleGenderChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        {genderError && (
                          <span className="error">{genderError}</span>
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="address1" className="form-label">
                          Address 1
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-map-marker-alt" />
                          </span>
                          <input
                            type="text"
                            name="address1"
                            className="form-control"
                            id="address1"
                            value={address1}
                            onChange={handleAddress1Change}
                          />
                        </div>
                        {address1Error && (
                          <span className="error">{address1Error}</span>
                        )}
                      </div>

                      <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="address2" className="form-label">
                          Address 2
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-map-marker-alt" />
                          </span>
                          <input
                            type="text"
                            name="address2"
                            className="form-control"
                            id="address2"
                            value={address2}
                            onChange={handleAddress2Change}
                          />
                        </div>
                        {address2Error && (
                          <span className="error">{address2Error}</span>
                        )}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-building" />
                          </span>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            id="city"
                            value={city}
                            onChange={handleCityChange}
                          />
                        </div>
                        {cityError && (
                          <span className="error">{cityError}</span>
                        )}
                      </div>
                      <div className="col-12 col-md-6">
                        <label htmlFor="postcode" className="form-label">
                          Postcode/Eircode
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            <i className="fa-solid fa-envelope" />
                          </span>
                          <input
                            type="text"
                            name="postcode"
                            className="form-control"
                            id="postcode"
                            value={postcode}
                            onChange={handlePostcodeChange}
                          />
                        </div>
                        {postcodeError && (
                          <span className="error">{postcodeError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <label htmlFor="Country" className="form-label">
                        Country
                      </label>
                      <div className="input-group has-validation">
                        <span
                          className="input-group-text"
                          id="inputGroupPrepend"
                        >
                          <i className="fa-solid fa-globe" />
                        </span>
                        <select
                          name="Country"
                          className="form-select"
                          id="Country"
                          value={country}
                          onChange={handleCountryChange}
                        >
                          <option value="">-- Select a country --</option>
                          <option value="UK">United Kingdom</option>
                          <option value="IR">Ireland</option>
                        </select>
                      </div>
                      {countryError && (
                        <span className="error">{countryError}</span>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          name="terms"
                          type="checkbox"
                          defaultValue
                          id="acceptTerms"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="acceptTerms"
                        >
                          I agree and accept the{" "}
                          <a
                            href="https://www.healthhero.com/terms-and-conditions"
                            target="_blank"
                          >
                            terms and conditions
                          </a>
                        </label>
                      </div>

                      {termsAcceptedError && (
                        <span className="error">{termsAcceptedError}</span>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          name="privacy"
                          type="checkbox"
                          defaultValue
                          id="privacy"
                          onChange={handleCheckbox1Change}
                        />
                        <label className="form-check-label" htmlFor="privacy">
                          I agree and accept the{" "}
                          <a
                            href="https://www.healthhero.com/privacy-policy"
                            target="_blank"
                          >
                            privacy and policy
                          </a>
                        </label>
                      </div>

                      {privacyError && (
                        <span className="error">{privacyError}</span>
                      )}
                    </div>

                    {error && (
                      <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="alert alert-danger">{error}</div>
                      </div>
                    )}
                    <div className="col-12 login mt-3 mb-3 text-center submit">
                      <button className="btn btn-primary" type="submit">
                        Save and Continue
                        <span className="ms-2" aria-hidden="true">
                          &rarr;
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default injectModels(["auth"])(PatientForm);
