import React, { useEffect, useState } from "react";
import { AdminService } from "../services";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const Contact = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const parse = require('html-react-parser');

  useEffect(async () => {
    setLoading(true);
    const response = await AdminService.getAllContents();
    if (response) {
      setLoading(false);
      response.map((item, index) => {
        if (item.page_name === "gewardzAddress") {
          setAddress(item.page_content);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  const handleNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
    setName(val);
  };

  const handleMessageChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
    setMessage(val);
  };

  const handleTitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setTitleError("Message is required");
    } else {
      setTitleError("");
    }
    setTitle(val);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setUserEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setUserEmailError("Enter a valid email");
    } else {
      setUserEmailError("");
    }

    setUserEmail(val);
  };

  const handleContactChange = (e) => {
    // const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length === 17) return false;
    if (val.trim() === "") {
      setContactError("Contact number is required"); //validations for phone
    } else if (!phoneRegex.test(val)) {
      setContactError("Enter a valid phone number (8 to 16 digits)");
    } else {
      setContactError("");
    }
    setContact(val.trim());
  };

  const resetSubmit = () => {
    setName(" ");
    setUserEmail(" ");
    setContact(" ");
    setMessage(" ");
    setTitle(" ");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    if (name === "") {
      setNameError(" Name is required");
      valid = false;
    } else {
      //submit response handle
      setNameError("");
    }
    if (userEmail === "") {
      setUserEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(userEmail)) {
      setUserEmailError("Enter a valid email");
      valid = false;
    } else {
      setUserEmailError("");
    }
    if (contact === "") {
      setContactError("Contact number is required");
      valid = false;
    } else if (!phoneRegex.test(contact)) {
      setContactError("Enter a valid phone number (8 to 16 digits)");
    } else {
      setContactError("");
    }
    if (message === "") {
      setMessageError("Message is required");
      valid = false;
    } else {
      setMessageError("");
    }
    if (title === "") {
      setTitleError("Reason for enquiry is required");
      valid = false;
    } else {
      setTitleError("");
    }

    if (!valid) {
      return;
    } else {
      setLoading(true);
      const data = {
        name: name,
        email: userEmail,
        contactno: contact,
        title: title,
        body: message,
      };

      try {
        const response = await AdminService.createEnquiry(data);

        if (response.message) {
          resetSubmit();
          toast.success("Enquiry submitted successfully!!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  return (
    <React.Fragment>
       {loading && (
            <div className="spinner">
              <div className="loader">
                {" "}
                <Circles
                  height="110"
                  width="110"
                  color="#FFF"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          )}
      <div className="contact-details">


        <section className="adr-ftr">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="uph2 mb-4">Contact Us</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-us">
         
          <div className="container">
            <div className="row">

            <div className="col-md-6 mb-3">

            <div className="adr-ftr-box">
                  <i className="fa-sharp fa-solid fa-location-dot" />
                  <h6>Our Address</h6>
                  <p>{parse(address)}</p>
                
              </div>
                <div className="contact-heading frame">
               
               
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d291.8806749430744!2d-6.343945150401295!3d54.17927179999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860db5587a745d1%3A0xa62a630c3870c04b!2sG%20P%20Boyle%20%26%20Co!5e0!3m2!1sen!2sin!4v1686041208089!5m2!1sen!2sin"
                    width={600}
                    height={450}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>




              <div className="col-md-6 mb-3">
                <div className="contact-heading">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="mb-1">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleNameChange}
                        
                      />
                      {nameError && <span className="error">{nameError}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="mb-1">Contact</label>
                      <input
                        type="text"
                        name="contact"
                        value={contact}
                        onChange={handleContactChange}
                        
                      />
                      {contactError && (
                        <span className="error">{contactError}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="mb-1">Email</label>
                      <input
                        type="text"
                        name="email"
                        value={userEmail}
                        onChange={handleEmailChange}
                        
                      />
                      {userEmailError && (
                        <span className="error">{userEmailError}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="mb-1">Reason for Enquiry</label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        onChange={handleTitleChange}
                      
                      />
                      {titleError && (
                        <span className="error">{titleError}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="mb-1">Message</label>
                      <textarea
                        className="form-control"
                        value={message}
                        onChange={handleMessageChange}
                        name="body"
                        rows={3}
                        defaultValue={""}
                      />
                      {messageError && (
                        <span className="error">{messageError}</span>
                      )}
                    </div>
                    <button type="submit">Send Message</button>
                  </form>
                </div>
              </div>
             
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Contact;
