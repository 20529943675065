import axios from "axios";

export const Login = async (data) => { 
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/login`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    localStorage.setItem("accessToken", response.data.access_token);
    localStorage.setItem("email", response.data.data.email);
    localStorage.setItem(
      "Subscription_status",
      response.data.data.subscription
    );
    localStorage.setItem("trial", response.data.data.trial);
    localStorage.setItem("user_id", response.data.data.id);
    localStorage.setItem("countary", response.data.data.country);
    localStorage.setItem("orgId", response.data.data.organisation_id);
    localStorage.setItem("checkoutURL", response.data.checkouturl);
    localStorage.setItem("tokenVal",response.data.data.uuid);
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const Register = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log(response, "response inside service page");
    if (response.status === 201) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    console.log(err, "Error inside service page");
    return Promise.reject(err);
  }
};

export const ForgotPassword = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/forgot-password`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log(response, "response inside AuthService");
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ResetPassword = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/reset-password`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("Internal server error"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
