import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { injectModels } from "../../redux/injectModels";
import { AdminService } from "../../services";
import { Circles } from "react-loader-spinner";
import { Routes } from "../../constants";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AppointmentBooking = (props) => {
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedConsultationType, setSelectedConsultationType] = useState("");
  const [listdata, setListData] = useState([]);
  const [loading, setLoading] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [totalRecords, setTotalRecords] = useState("");

  const [selectError, setSelectError] = useState(false);

  const startIdx = (pageNumber - 1) * perPage;
  const endIdx = startIdx + perPage;

  const param = useParams();
  const history = useHistory();
  const patient_id = param.pid;
  const dep_Id = param.did;

  console.log(param,"param")
  localStorage.setItem("patientId", patient_id);
  localStorage.setItem("dependent_Id", dep_Id);

  const handleSubmit = async () => {
    if (!selectedConsultationType) {
      setSelectError(true);
      return;
    } else {
      setSelectError(false);
    }
    try {
      setPageNumber(1); // Set pageNumber to 1 before making the API call
      let genderValue = selectedGender; // Store the selectedGender value in a variable

      if (selectedGender === "No Preference") {
        genderValue = "";
        const data = {
          gender: genderValue,
          specialties: selectedSpecialty,
          consultationtype: selectedConsultationType,
          pid: patient_id,
          pagenumber: pageNumber,
        };
        setLoading(true);
        const response = await AdminService.listApppointments(data);
        setTotalRecords(response.totalRecords);
        setListData(response.message.data);
        setLoading(false);
      } else {
        const data = {
          gender: selectedGender,
          specialties: selectedSpecialty,
          consultationtype: selectedConsultationType,
          pid: patient_id,
          pagenumber: pageNumber,
        };
        setLoading(true);
        const response = await AdminService.listApppointments(data);
        setTotalRecords(response.totalRecords);
        setListData(response.message.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleClick = async (item, date, time, gender) => {
    try {
      const result = await Swal.fire({
        title: `${date} <br /> ${time}`,
        text: "Do you want to Hold this Appointment?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const data = {
          userid: patient_id,
          appointmentId: item.id,
        };
        const response = await AdminService.holdAppointment(data);
        setLoading(false);
        if (response.status === true) {
          history.push({
            pathname: Routes.BOOKINGFORM,
            state: { item, gender, listdata, selectedConsultationType },
          });
        }
      }
    } catch (err) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    history.push(Routes.PATIENTLIST);
  };
  const handleNextPage = async () => {
    if (pageNumber * perPage < totalRecords) {
      const nextPageNumber = pageNumber + 1;

      try {
        const data = {
          gender: selectedGender,
          specialties: selectedSpecialty,
          consultationtype: selectedConsultationType,
          pid: patient_id,
          pagenumber: nextPageNumber,
        };
        setLoading(true);
        const response = await AdminService.listApppointments(data);
        const updatedListData = listdata.concat(response.message.data);
        setPageNumber(nextPageNumber);
        setListData(updatedListData);
        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };


  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}

      {/* Filter Section Start */}

      <section className="booking-filter">
        <div className="container">
          <div className="row mb-4">
            <div
              className="col-md-12 d-flex  flt"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <button className="back-button me-3" onClick={handleGoBack}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <h2 className="uph2 xxl-mb-4 lg-mb-4 md-mb-4 mb-0 pagetitle">
                Book Appointment
              </h2>
            </div>
            <div className="col-md-12">
              <div className="card n-card appointment">
                <div className="card-body p-0">
                  <div className="row g-3">
                    <div className="col-md-3">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedGender}
                        onChange={(e) => setSelectedGender(e.target.value)}
                      >
                        <option value="">Select Doctor's Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="No Preference">No Preference</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedSpecialty}
                        onChange={(e) => setSelectedSpecialty(e.target.value)}
                      >
                        <option value="">Select Specialties</option>
                        <option value="generalpractitioner">
                          General Practitioner
                        </option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedConsultationType}
                        onChange={(e) =>
                          setSelectedConsultationType(e.target.value)
                        }
                      >
                        <option value="">Select ConsultationType</option>
                        <option value="phone">Phone</option>
                        <option value="video">Video</option>
                      </select> */}
                      <select
                        className={`form-select ${
                          selectError ? "select-error" : ""
                        }`}
                        aria-label="Default select example"
                        value={selectedConsultationType}
                        onChange={(e) => {
                          setSelectedConsultationType(e.target.value);
                          setSelectError(false);
                        }}
                      >
                        <option value="">Select ConsultationType</option>
                        <option value="phone">Phone</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                    <div className="col-md-3 login">
                      <div className="filter">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Select Time
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Filter Section End */}
          {/* Filter Result Section Start */}

          <div className="row apptB-result g-3 ">
            {listdata && listdata.length > 0 ? (
              <div className="col-md-12">
                <h5>Available Time</h5>
              </div>
            ) : (
              <div className="col-md-12">
                {/* <h5>No Available Time</h5> */}
              </div>
            )}

            {listdata &&
              listdata.slice(startIdx, endIdx).map((item, index) => {
                const date = new Date(item.attributes.start);
                const options = {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                  timeZone: "Europe/London",
                };
                const options1 = {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZone: "Europe/London",
                };
                const formattedDate = date.toLocaleDateString("en-GB", options);
                const formattedTime = date.toLocaleTimeString(
                  "en-GB",
                  options1
                );
                return (
                  <div key={item.id}
                  className="col">
                    <div
                      className="card n-card p-3 date-time mb-0 brdr-blue-left"
                      onClick={() =>
                        handleClick(
                          item,
                          formattedDate,
                          formattedTime,
                          selectedGender,
                          selectedConsultationType
                        )
                      }
                    >
                      <ul>
                        <li className="appt-time">
                          <strong>
                            <i className="fa-regular fa-calendar"></i>{" "}
                            {formattedDate}
                          </strong>
                          <strong>
                            <i className="fa-regular fa-clock"></i>{" "}
                            {formattedTime}
                          </strong>
                        </li>
                        {/* <li>
                          <span>Consultation Type :</span>{" "}
                          <strong> {item.attributes.consultationType}</strong>
                        </li>
                        <li>
                          <span>status :</span>{" "}
                          <strong>{item.attributes.status}</strong>
                        </li> */}
                        <li>
                          <span>Specialties :</span>{" "}
                          <strong>General Practitioner</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          {listdata && listdata.length > 0 ? (
            <div className="pagination">
              <button
                className="btn btn-secondary"
                disabled={pageNumber === 1}
                onClick={handlePrevPage}
              >
                Prev
              </button>
              <span className="page-number">{pageNumber}</span>
              <button
                className="btn btn-secondary"
                disabled={pageNumber * perPage >= totalRecords.length}
                onClick={handleNextPage}
              >
                Next
              </button>
              {/* <button
                className="btn btn-secondary"
                disabled={pageNumber * perPage >= parseInt(totalRecords)}
                onClick={handleNextPage}
              >
                Next
              </button> */}
            </div>
          ) : (
            <div className="pagination"></div>
          )}
        </div>
      </section>
      {/* Filter Result Section End */}
    </React.Fragment>
  );
};

export default injectModels(["auth"])(AppointmentBooking);
