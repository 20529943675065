import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { injectModels } from "../../redux/injectModels";
import { Routes } from "../../constants";
import { Circles } from "react-loader-spinner";
import Swal from "sweetalert2";
import { AdminService } from "../../services";

const Dashboard = (props) => {
  const orgId = localStorage.getItem("orgId");
  const [listData, setListData] = useState("");
  const [patientList, setPatientList] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [managerialUkNational, setManagerialUkNational] = useState("");
  const [debtAdvisoryUkNational, setDebtAdvisoryUkNational] = useState("");
  const [wellnessCounselingUkNational, setWellnessCounsellingUkNational] =
    useState("");

  const [managerialUkInterNational, setManagerialUkInterNational] =
    useState("");
  const [debtAdvisoryUkInterNational, setDebtAdvisoryUkInterNational] =
    useState("");
  const [
    wellnessCounselingUkInterNational,
    setWellnessCounsellingUkInterNational,
  ] = useState("");

  const [managerialIreNational, setManagerialIreNational] = useState("");
  const [wellnessCounselingIreNational, setWellnessCounsellingIreNational] =
    useState("");
  const [debtAdvisoryIreNational, setDebtAdvisoryIreNational] = useState("");

  const [managerialIreInterNational, setManagerialIreInterNational] =
    useState("");
  const [
    wellnessCounselingIreInterNational,
    setWellnessCounsellingIreInterNational,
  ] = useState("");
  const [debtAdvisoryIreInterNational, setDebtAdvisoryIreInterNational] =
    useState("");

  const [bookingStatus, setBookingStatus] = useState("");
  const [isAbove16, setIsAbove16] = useState(false);

  const parse = require("html-react-parser");

  useEffect(async () => {
    setLoading(true);
    const response = await AdminService.getAllContents();
    if (response) {
      setLoading(false);
      response.map((item, index) => {
        if (item.page_name === "support-number-Managerial-UK-National") {
          setManagerialUkNational(item.page_content);
        }
        if (
          item.page_name === "support-number-Legal-Debt-Advisory-UK-National"
        ) {
          setDebtAdvisoryUkNational(item.page_content);
        }
        if (
          item.page_name === "support-number-Wellness-Counselling-UK-National"
        ) {
          setWellnessCounsellingUkNational(item.page_content);
        }

        if (item.page_name === "support-number-Managerial-UK-International") {
          setManagerialUkInterNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Legal-Debt-Advisory-UK-International"
        ) {
          setDebtAdvisoryUkInterNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Wellness-Counselling-UK-International"
        ) {
          setWellnessCounsellingUkInterNational(item.page_content);
        }

        if (item.page_name === "support-number-Managerial-Ireland-National") {
          setManagerialIreNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Wellness-Counselling-Ireland-National"
        ) {
          setWellnessCounsellingIreNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Legal-Debt-Advisory-Ireland-National"
        ) {
          setDebtAdvisoryIreNational(item.page_content);
        }
        if (
          item.page_name === "support-number-Managerial-Ireland-International"
        ) {
          setManagerialIreInterNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Wellness-Counselling-Ireland-International"
        ) {
          setWellnessCounsellingIreInterNational(item.page_content);
        }
        if (
          item.page_name ===
          "support-number-Legal-Debt-Advisory-Ireland-International"
        ) {
          setDebtAdvisoryIreInterNational(item.page_content);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  const getBookingDetails = async () => {
    setLoading(true);
    const response = await AdminService.getBookingDetails(user_id);

    if (response) {
      setBookingStatus(response.data.bookingStatus);
      setLoading(false);
    } else {
      setLoading(false);
      setBookingStatus("");
    }
  };

  const getUserDetails = async () => {
    setLoading(true);
    const response = await AdminService.getUserDetails(user_id);
    setCountry(response.country);
    setLoading(false);
  };

  const handleCancel = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to Cancel this Appointment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const response = await AdminService.CancelAppointment(user_id, id);
        if (response) {
          setLoading(false);
          window.location.reload();
        } else {
        }
      } else if (result.isDismissed) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const user_id = localStorage.getItem("user_id");
  const fetchUpcomingAppointments = async () => {
    setLoading(true);
    const response = await props.auth.getUpcomingBookingDetails(user_id);
    setListData(response.data.upcoming);
    setLoading(false);
  };

  const getPatientList = async () => {
    setLoading(true);
    const response = await AdminService.getPatientList(user_id);
    setPatientList(response.message);

    setLoading(false);
  };

  // const openConfirmationDialog = () => {
  //   Swal.fire({
  //     title: "Are you above 16?",
  //     text: "Please confirm your age to continue.",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       if (patientList && patientList.length > 0) {
  //         setIsAbove16(true);
  //         props.history.push(Routes.PATIENTLIST);
  //       } else {
  //         setIsAbove16(true);
  //         props.history.push(Routes.PATIENTFORM);
  //       }
  //     } else {
  //       setIsAbove16(false);
  //     }
  //   });
  // };

  useEffect(() => {
    getPatientList();
    fetchUpcomingAppointments();
    getBookingDetails();
    getUserDetails();
  }, []);

  return (
    <React.Fragment>
      <div
        className="tab-pane fade show active"
        id="dashboard"
        role="tabpanel"
        aria-labelledby="dashboard-badge"
      >
        {loading && (
          <div className="spinner">
            <div className="loader">
              {" "}
              <Circles
                height="110"
                width="110"
                color="#FFF"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
              />
            </div>
          </div>
        )}

        <section className="stre-appo">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="appoitment-box bl align-items-center brdr-none">
                  <div className="appb-content well">
                    <div className="row">
                      <div className="col-md-7 d-flex align-items-center">
                        {/* <h3 className="m-0">24/7 GP Appointment</h3> */}
                        <div className="gp-content">
                          <div className="appb-icon">
                            <i className="fa-solid fa-calendar-check"></i>
                          </div>
                          <p className="m-6">
                            Click on the button to arrange your GP phone or
                            video <br className="text-br" /> appointment for the
                            next available time that suits you.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5">
                        {patientList && patientList.length > 0 ? (
                          <Link
                            to={Routes.PATIENTLIST}
                            className="org-big-btn btn-bx-shadow"
                          >
                            <i className="fa-solid fa-calendar-plus"></i>
                            <span>Book Now</span>
                          </Link>
                        ) : (
                          <Link
                            to={Routes.PATIENTFORM}
                            className="org-big-btn btn-bx-shadow"
                          >
                            <i className="fa-solid fa-calendar-plus"></i>
                            <span>Book Now</span>
                          </Link>
                        )}
                      </div>

                      {/* <div className="col-md-5">
                        <div className="fgtre">
                        <button
                          onClick={openConfirmationDialog}
                          className="org-big-btn btn-bx-shadow"
                        >
                          <i className="fa-solid fa-calendar-plus"></i>
                          <span>Book Now</span>
                        </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="uph2 mb-4">Upcoming Appointments</h2>
              </div>

              <div className="col-xxl-10 col-lg-10 col-md-12">
                <div
                  className={
                    listData && listData.length > 2
                      ? "upc-appt scroll-bx"
                      : "upc-appt"
                  }
                >
                  {listData && listData.length > 0 ? (
                    <React.Fragment>
                      {listData &&
                        listData.map((item, index) => {
                          const date = new Date(item.appointment_time);

                          // const formattedDate = date.toLocaleDateString();

                          const options3 = {
                            month: "long",
                            timeZone: "Europe/London",
                          };
                          const options2 = {
                            day: "numeric",
                            timeZone: "Europe/London",
                          };
                          const options1 = {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZone: "Europe/London",
                          };
                          const month = date.toLocaleDateString(
                            "en-US",
                            options3
                          );
                          const day = date.toLocaleDateString(
                            "en-US",
                            options2
                          );
                          const formattedTime = date.toLocaleTimeString(
                            "en-GB",
                            options1
                          );
                          return (
                            <div key={index}>
                              <div className="card n-card brdr-blck-left mb-3">
                                <div className="upc-sec">
                                  <div className="upc-date">
                                    <span>{month}</span>
                                    <h3>{day}</h3>
                                    <strong>{formattedTime}</strong>
                                  </div>
                                  <div className="upc-specialist">
                                    <div className="upc-detail">
                                      <div className="detail-upt">
                                        <div className="doc-icon">
                                          <i className="fa-solid fa-user" />
                                          {item.dependent_id !== 0 ? (
                                            <h3>
                                              {item.dependent[0].firstName + " " + item.dependent[0].lastName}
                                            </h3>
                                          ) : (
                                            <h3>
                                              {item.firstName
                                                ? item.firstName + " " + item.lastName
                                                : "_"}
                                            </h3>
                                          )}
                                        </div>

                                        <h4>
                                          General Practitioner
                                          <span>
                                            <i className="fa-solid fa-mars"></i>
                                            {item.gender_type
                                              ? item.gender_type
                                              : "_"}
                                          </span>
                                        </h4>
                                      </div>
                                      <p>
                                        {item.reason_for_booking
                                          ? item.reason_for_booking
                                          : "_"}
                                      </p>
                                    </div>

                                    <div className="upc-type ps-4">
                                      <p className="mb-2">
                                        <small>Consultation Type</small>
                                        <br />
                                        <strong>
                                          {item.consultation_type
                                            ? item.consultation_type
                                                .charAt(0)
                                                .toUpperCase() +
                                              item.consultation_type.slice(1)
                                            : "_"}
                                        </strong>
                                      </p>
                                      <button
                                        onClick={() => handleCancel(item.id)}
                                        className="dark-btn"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="card n-card brdr-blue-left mb-3">
                        <label>You Have No Upcoming Appointment</label>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-md-2 apt-booking">
                <div className="card n-card brdr-blue-bottom mb-4">
                  <div className="booked-appt mb-2">
                    <i className="fa-regular fa-calendar-check"></i>
                    <div className="bk-appt-text">
                      <h2>{bookingStatus.booked}</h2>
                    </div>
                  </div>
                  <p className="mb-0">
                    <small>Appointment Booked</small>
                  </p>
                </div>
                <div className="card n-card brdr-blue-bottombl mb-4">
                  <div className="booked-appt bl mb-2">
                    <i className="fa-regular fa-calendar-xmark" />

                    <div className="bk-appt-text">
                      <h2>{bookingStatus.cancelled}</h2>
                    </div>
                  </div>
                  <p className="mb-0">
                    <small>Appointment Canceled</small>
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-0 mt-md-4">
			{orgId =='15' ? (
				<div className="col-xxl-12 col-lg-12 col-md-6 apt">
                <div className="appoitment-box dr">
                  <div className="appb-icon">
                    <i className="fa-solid fa-notes-medical"></i>
                  </div>
                  <div className="appb-content fith">
                    <div className="appcb">
                      <h3>Wellness Counselling Service</h3>
                      <p>
                        Click on the number below to arrange an appointment. Be
                        assured that all calls are treated in strict confidence.
                      </p>
                    </div>

                    <div>
                      {country && (country === "EU" || country === "IR") ? (
                        <div className="appb-links-no">
                          <h6>Ireland/EU:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingIreNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(wellnessCounselingIreNational)}{" "}
                                </a>
                              </strong>
                            </li>
                            <li>
                              {" "}
                              <span>
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>{" "}
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingIreInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(
                                    wellnessCounselingIreInterNational
                                  )}{" "}
                                </a>
                              </strong>{" "}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="appb-links-no">
                          <h6>UK:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingUkNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(wellnessCounselingUkNational)}{" "}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingUkInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(
                                    wellnessCounselingUkInterNational
                                  )}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
			) : (
                <React.Fragment>
              <div className="col-xxl-4 col-lg-4 col-md-6 apt">
                <div className="appoitment-box dr">
                  <div className="appb-icon">
                    <i className="fa-solid fa-notes-medical"></i>
                  </div>
                  <div className="appb-content fith">
                    <div className="appcb">
                      <h3>Wellness Counselling Service</h3>
                      <p>
                        Click on the number below to arrange an appointment. Be
                        assured that all calls are treated in strict confidence.
                      </p>
                    </div>

                    <div>
                      {country && (country === "EU" || country === "IR") ? (
                        <div className="appb-links-no">
                          <h6>Ireland/EU:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingIreNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(wellnessCounselingIreNational)}{" "}
                                </a>
                              </strong>
                            </li>
                            <li>
                              {" "}
                              <span>
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>{" "}
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingIreInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(
                                    wellnessCounselingIreInterNational
                                  )}{" "}
                                </a>
                              </strong>{" "}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="appb-links-no">
                          <h6>UK:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingUkNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(wellnessCounselingUkNational)}{" "}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    wellnessCounselingUkInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(
                                    wellnessCounselingUkInterNational
                                  )}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-6 apt">
                <div className="appoitment-box bl">
                  <div className="appb-icon">
                    <i className="fa-solid fa-suitcase-medical"></i>
                  </div>
                  <div className="appb-content fith">
                    <div className="appcb">
                      <h3>Managerial Support </h3>
                      <p>
                        Click on the number below to arrange an appointment. Be
                        assured that all calls are treated in strict confidence.
                      </p>
                    </div>
                    <div>
                      {country && (country === "EU" || country === "IR") ? (
                        <div className="appb-links-no">
                          <h6>Ireland/EU:</h6>

                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a href={`tel:${parse(managerialIreNational)}`}>
                                  {" "}
                                  {parse(managerialIreNational)}{" "}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    managerialIreInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(managerialIreInterNational)}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="appb-links-no">
                          <h6>UK:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :
                              </span>
                              <strong>
                                <a href={`tel:${parse(managerialUkNational)}`}>
                                  {" "}
                                  {parse(managerialUkNational)}{" "}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    managerialUkInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(managerialUkInterNational)}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-lg-4 col-md-6 apt">
                <div className="appoitment-box dr">
                  <div className="appb-icon">
                    <i className="fa-solid fa-scale-balanced"></i>
                  </div>
                  <div className="appb-content fith">
                    <div className="appcb">
                      <h3>Legal and Debt Advisory Services</h3>
                      <p>
                        Click on the number below to arrange an appointment. Be
                        assured that all calls are treated in strict confidence.
                      </p>
                    </div>

                    <div>
                      {country && (country === "EU" || country === "IR") ? (
                        <div className="appb-links-no">
                          <h6>Ireland/EU:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :{" "}
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(debtAdvisoryIreNational)}`}
                                >
                                  {parse(debtAdvisoryIreNational)}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    debtAdvisoryIreInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(debtAdvisoryIreInterNational)}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="appb-links-no">
                          <h6>UK:</h6>
                          <ul>
                            <li>
                              <span>
                                <i className="fa-solid fa-phone"></i> National :{" "}
                              </span>
                              <strong>
                                <a
                                  href={`tel:${parse(debtAdvisoryUkNational)}`}
                                >
                                  {" "}
                                  {parse(debtAdvisoryUkNational)}{" "}
                                </a>
                              </strong>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <i className="fa-solid fa-phone"></i> INT :{" "}
                              </span>
                              <strong>
                                {" "}
                                <a
                                  href={`tel:${parse(
                                    debtAdvisoryUkInterNational
                                  )}`}
                                >
                                  {" "}
                                  {parse(debtAdvisoryUkInterNational)}{" "}
                                </a>{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
			  </React.Fragment> )}
			  
            </div>
          </div>
        </section>
      </div>
      <section></section>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(Dashboard);
