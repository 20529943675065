import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";


ReactDOM.hydrate(
  <React.StrictMode>
    <Provider store={store}>
       <App />
       </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);



// File Created By sourav on 01/05/2023