import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import CustomAuthRoute from "./CustomAuthRoute";
import CustomAdminRoute from "./CustomAdminRoute";
import CustomPublicRoute from "./CustomPublicroute";
import { Routes } from "./constants";
import { Login, Register, Dashboard, Error, ForgotPassword, Home, Booking, 
   Subscription, ChangePassword, Contact, Faq, TermsAndCondition, PrivacyPolicy, ThankYou, BookingForm, ResetPassword, ProductSubscription, PatientForm, DeclineSubscription, PatientUpdatedForm, PatientList, DependentForm } from "./component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <Switch>
          <Route exact path={Routes.DEFAULT} component={Home} />
          <CustomAuthRoute exact path={Routes.LOGIN} component={Login} />
          <CustomAuthRoute exact path={Routes.REGISTER} component={Register} />
          <CustomAuthRoute exact  path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
          <CustomAuthRoute exact  path={Routes.RESET_PASSWORD} component={ResetPassword} />
          <CustomPublicRoute exact path={Routes.CONTACT} component={Contact} />
          <CustomPublicRoute exact path={Routes.FAQ} component={Faq} />
          <CustomPublicRoute exact path={Routes.TERMSANDCONDITION} component={TermsAndCondition} />
          <CustomPublicRoute exact path={Routes.PRIVACYANDPOLICY} component={PrivacyPolicy} />
          <CustomPublicRoute exact path={Routes.THANKYOUPAGE} component={ThankYou} />
          <CustomPublicRoute exact path={Routes.FAILEDSUBSCRIPTION} component={DeclineSubscription} />
          <CustomPublicRoute exact path={Routes.SUBSCRIPTION} component={Subscription} />
          <CustomPublicRoute exact path={Routes.PRODUCTSUBSCRIPTION} component={ProductSubscription} />
          <CustomAdminRoute exact path={Routes.DASHBOARD} component={Dashboard} />
          <CustomAdminRoute exact path={Routes.CHANGEPASSWORD} component={ChangePassword} />
          <CustomAdminRoute exact path={Routes.BOOKINGFORM} component={BookingForm} />
          <CustomAdminRoute exact path={Routes.PATIENTLIST} component={PatientList} />
          <CustomAdminRoute exact path={Routes.PATIENTFORM} component={PatientForm} />
          <CustomAdminRoute exact path={Routes.DEPENDENTFORM+"/:pid"} component={DependentForm} />
          <CustomAdminRoute exact path={Routes.BOOKING+"/:pid"+"/:did"} component={Booking} />
          <CustomAdminRoute exact path={Routes.PATIENTUPDATEDFORM+"/:pid"} component={PatientUpdatedForm} />
          <Route exact path={Routes.ERROR} component={Error} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;

// File Created By sourav on 01/05/2023
