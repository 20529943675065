import React from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Routes } from "../constants";
import { injectModels } from "../redux/injectModels";

const Subscription = (props) => {

  const history = useHistory();

  const handleSubscribe = () => {
    history.push(Routes.CHECKOUT);
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Cancel the Subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    })
      .then((result) => {
        props.auth.logout();
        localStorage.clear();
        console.log(result);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const SubscriptionStatus = localStorage.getItem("Subscription_status");
  return (
    <React.Fragment>
      <div>
        <section className="Gewardz-program">
          <div className="health-benifits mt-xl-0 mt-lg-2 mt-md-1 mt-3  mb-xl-5 mb-lg-3 mb-md-1 mb-2">
            <h2>Gewardz Assist Program</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="Gewardz-program-content">
                  <span>
                    Bringing together the best providers in health, wellness and
                    hotel savings as one combined service to offer business
                    owners something unique to recognise and reward their
                    employees.
                  </span>
                  <br />
                  <br />
                  <h6>All for a small monthly fee per employee</h6>
                  <h2>£9.99 per month (+ vat)</h2>
                </div>
                {SubscriptionStatus == 0 && (
                  <div className="subscribe-now">
                    <button onClick={handleSubscribe}>Subscribe Now</button>
                  </div>
                )}
                {SubscriptionStatus == 1 && (
                  <div className="subscribe-now">
                    <button onClick={handleCancel}>Cancel Subscription</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="ftr-benifits">
          <div className="health-benifits mt-xl-5 mt-lg-3 mt-md-1 mt-3  mb-xl-5 mb-lg-3 mb-md-1 mb-2">
            <h2>Gewardz Assist Program benefits </h2>
          </div>
          <div className="container pr">
            <div className="row">
              <div className="col-md-6">
                <div className="ftr-benifits-img">
                  <img
                    src="assets/img/appointment-new.jpg"
                    className="img-fluid"
                    alt="appointment"
                  />
                </div>
              </div>
              <div className="col-md-6 justify-content-center d-flex flex-column align-items-start">
                <div className="ftr-benifits-img">
                  <h2>Unlimited Doctor Appointments &amp; Perscriptions</h2>
                  <p>
                    With GP access, our plan gives you and your family{" "}
                    <strong>Unlimited </strong> online doctor appointments at a
                    time when you need it. Your membership covers you as well as
                    your entire household. Where appropriate, the GP can even
                    arrange a prescription for you. This service is available to
                    the entire household
                  </p>
                </div>
              </div>
            </div>
            <div className="row xl-mt-5 lg-mt-5 xl-md-5  mt-2">
              <div className="col-md-6 justify-content-center d-flex flex-column align-items-start we">
                <div className="ftr-benifits-img ">
                  <h2>Wellness</h2>
                  <p>
                    We provide a complete mental health and wellbeing service,
                    integrating several clinically proven counselling approaches
                    to support people - from everyday mental wellbeing needs to
                    individual critical incident support where necessary. This
                    service is available to the entire household
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ftr-benifits-img">
                  <img
                    src="assets/img/mental-health.jpg"
                    className="img-fluid"
                    alt="mental-health"
                  />
                </div>
              </div>
            </div>
            <div className="row xl-mt-5 lg-mt-5 xl-md-5  mt-2">
              <div className="col-md-6">
                <div className="ftr-benifits-img">
                  <img
                    src="assets/img/advisory.jpg"
                    className="img-fluid"
                    alt="advisory"
                  />
                </div>
              </div>
              <div className="col-md-6 justify-content-center d-flex flex-column align-items-start">
                <div className="ftr-benifits-img ">
                  <h2>Specialist Advisory Services</h2>
                  <p>
                    Whether you are facing a legal questions or looking for debt
                    management support, with one phonecall we will provide you
                    with access to specialist qualified advisors who can help
                    with your questions.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row xl-mt-5 lg-mt-5 xl-md-5  mt-2">
              <div className="col-md-6 justify-content-center d-flex flex-column align-items-start">
                <div className="ftr-benifits-img ">
                  <h2>Members only travel club</h2>
                  <p>
                    Whether travelling for business or leisure, being able to
                    reduce the cost is something we all seek to do. As part of
                    our services, we established a private members travel club
                    to help reduce the cost of travel by 10-15% per annum. Of
                    course these savings extend to leisure travel and so it
                    creates an opportunity to reward employees with access to
                    our travel club so that they can reduce the cost of their
                    family travel during the year.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ftr-benifits-img">
                  <img
                    src="assets/img/travel-industry.jpg"
                    className="img-fluid"
                    alt="travel"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-service">
          <div className="container">
            <div className="row">
              <p>
                Our services are provided in conjunction with Health Hero's, who
                are helping improve lives by leveraging technology to put
                medical advice from clinical experts into the hands of millions
                - no matter where, or when, they need it.
              </p>
              {SubscriptionStatus == 0 && (
                <div className="subscribe-now">
                  <button onClick={handleSubscribe}>Subscribe Now</button>
                </div>
              )}
              {SubscriptionStatus == 1 && (
                <div className="subscribe-now">
                  <button onClick={handleCancel}>Cancel Subscription</button>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="product">
          <div className="container">
            <div className="row">
              <p>Our mission is to</p>
              <h6>"level the playing field" </h6>
              <p className="pt-0">
                by providing the very best products and services to the people
                <br />
                and businesses that need them most.
              </p>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(Subscription);
