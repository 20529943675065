import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../constants";
import { JWT } from "../../shared";

const PublicHeader = () => {
  const isValidToken = JWT.isValidToken();

  return (
    <section className="home-header fixed-top sticked">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-5">
            <div className="home-logo">
              <Link to={Routes.DEFAULT}>
                <img src="assets/img/logo-home.png" alt="logo" />
              </Link>
              {/* <img src="assets/img/logo-home-b.png" className="b-logo" alt="logo"> */}
            </div>
          </div>
          {!isValidToken && (
            <div className="col-md-6 col-7">
              <div className="login-btn">
                <Link to={Routes.LOGIN}>Login</Link>
              </div>
            </div>
          )}
          {isValidToken && (
            <div className="col-md-6 col-7">
              <div className="login-btn">
                <Link to={Routes.DASHBOARD}>Dashboard</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PublicHeader;
