import React, { useState, useEffect } from "react";
import { AuthService } from "../../services";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Routes } from "../../constants";
import { Circles } from "react-loader-spinner";
import { injectModels } from "../../redux/injectModels";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Register = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [countryError, setCountryError] = useState("");
  const [orgID, setOrgID] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [cPasswordError, setCpassError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsAcceptedError, setTermsAcceptedError] = useState("");
  const [uriId, setUriId] = useState("");
  const [uriImage, setUriImage] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFirstNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setFirstNameError("First name is required");
    } else {
      setFirstNameError("");
    }

    setFirstName(val);
  };

  const handleLastNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setLastNameError("Last name is required");
    } else {
      setLastNameError("");
    }

    setLastName(val);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid email");
    } else {
      setEmailError("");
    }

    setEmail(val);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();

    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;

    if (val === "") {
      setPassError("Password is required");
    } else if (!passRegex.test(val)) {
      setPassError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
    } else {
      setPassError("");
    }

    setPassword(val);
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      //repeat password validation
      setCpassError("Confirm Password is required");
    } else if (val !== password) {
      setCpassError("Password does not match");
    } else {
      setCpassError("");
    }
    setCPassword(val);
  };

  const handleCountryChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length === 17) return false;
    if (val.trim() === "") {
      setCountryError("Country name is required");
    } else {
      setCountryError("");
    }
    setCountry(val.trim());
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };
  const queryParams = new URLSearchParams(window.location.search);
  const uriData = queryParams.get("uri");
  const tokenData = queryParams.get("token");

  useEffect(async () => {
    if(window.location.href=="http://bookings.zumohealth.co.uk/register" || window.location.href=="https://bookings.zumohealth.co.uk/register"){
		history.push(Routes.LOGIN);
	}
    if (uriData) {
      setLoading(true);
      const orgDetails = await props.auth.getOrgById(uriData,tokenData);
      if (orgDetails.message.length > 0) {
        let id = orgDetails.message[0].id;
        setUriId(`/login?uri=${uriData}&token=${tokenData}`);
        setUriImage(orgDetails.message && orgDetails.message[0].image);
        setOrgID("" + orgDetails.message[0].id + "");
        localStorage.setItem("uriToken", tokenData);
        localStorage.setItem("uripath", uriData);
        localStorage.setItem("uriLogo", orgDetails.message[0].image);
        if (orgDetails) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      localStorage.removeItem("uriLogo");
      localStorage.removeItem("uripath");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    const passRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>=''])[A-Za-z\d@$!%*?&!"#\$%&\(\)*\+,./:;?@\[\]^_`{|}~<>='"]{10,35}$/;
    if (firstName === "") {
      setFirstNameError("First Name is required");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (lastName === "") {
      setLastNameError("Last Name is required");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPassError("Password is required");
      valid = false;
    } else if (!passRegex.test(password)) {
      setPassError(
        "Requires 10-35 characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character."
      );
      valid = false;
    } else {
      setPassError("");
    }
    if (cpassword !== password) {
      setCpassError("Password does not Match");
      valid = false;
    } else {
      setCpassError("");
    }
    if (country === "") {
      setCountryError("Country Name is required");
      valid = false;
    } else {
      setCountryError("");
    }

    if (!termsAccepted) {
      setTermsAcceptedError("You must agree before submitting.");
      valid = false;
    } else {
      setTermsAcceptedError("");
    }

    if (!valid) {
      return;
    } else {
      setError("");
      // Handle registration logic here
      setLoading(true);
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        password_confirmation: cpassword,
        organisation_id: orgID ? orgID : "",
        tokenVal: tokenData ? tokenData : "",
        country: country,
      };

      try { 
        const response = await AuthService.Register(data);

        if (response.message === "User successfully registered") {
          toast.success("User registered successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });

          setLoading(false);

          await AuthService.Login({ 
            email: email,
            password: password,
            organisation_id: orgID,
            tokenVal: tokenData ? tokenData : "",
          });
          if (orgID) {
            history.push(Routes.DASHBOARD);
          } else {
            history.push(Routes.PRODUCTSUBSCRIPTION);
          }
        } else {
          console.log(response.message);
        }
      } catch (error) {
        if (error.message === "Request failed with status code 422") {
          setLoading(false);
          toast.error("This email has already been registered.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <main>
      <section className="login-bg-image">
        {loading && (
          <div className="spinner">
            <div className="loader">
              {" "}
              <Circles
                height="110"
                width="110"
                color="#FFF"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        )}
        <div className="container">
          <section className="section register  d-flex flex-column align-items-center justify-content-center py-3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-8  d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center pb-3">
                    <Link
                      to={
                        uriImage ? `/register?uri=${uriData}&token=${tokenData}` : Routes.DEFAULT
                      }
                      className="logo d-flex align-items-center w-auto"
                    >
                      {}
                      {loading ? (
                        <div>
                          <img
                            src="/assets/img/placeholder.png"
                            alt="Loading"
                          />
                        </div>
                      ) : (
                        <img src={uriImage || "/assets/img/logo.png"} alt="" />
                      )}
                    </Link>
                  </div>

                  {/* End Logo */}
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-1 pb-3">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Create an Account
                        </h5>
                      </div>
                      <form
                        className="row g-2 needs-validation account-login"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12 ">
                          <label htmlFor="fname" className="form-label">
                            First Name
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-user" />
                            </span>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="fname"
                              value={firstName}
                              onChange={handleFirstNameChange}
                            />
                          </div>
                          {firstNameError && (
                            <span className="error">{firstNameError}</span>
                          )}
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="lname" className="form-label">
                            Last Name
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-user" />
                            </span>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="lname"
                              value={lastName}
                              onChange={handleLastNameChange}
                            />
                          </div>
                          {lastNameError && (
                            <span className="error">{lastNameError}</span>
                          )}
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-envelope" />
                            </span>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              id="email"
                              value={email}
                              onChange={handleEmailChange}
                            />
                          </div>
                          {emailError && (
                            <span className="error">{emailError}</span>
                          )}
                        </div>

                        <div className="col-12 ">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group has-validation">
                            {/* <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-lock" />
                            </span>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={handlePasswordChange}
                            /> */}
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-lock" />
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary eye-splash"
                              onClick={toggleShowPassword}
                            >
                              {showPassword ? (
                                <i className="fa-solid fa-eye-slash" />
                              ) : (
                                <i className="fa-solid fa-eye" />
                              )}
                            </button>
                          </div>
                          {passError && (
                            <span className="error">{passError}</span>
                          )}
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="password" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-lock" />
                            </span>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              id="password"
                              value={cpassword}
                              onChange={handleConfirmPasswordChange}
                            />
                          </div>
                          {cPasswordError && (
                            <span className="error">{cPasswordError}</span>
                          )}
                        </div>

                        <div className="col-12">
                          <label htmlFor="Country" className="form-label">
                            Country
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-globe" />
                            </span>
                            <select
                              name="Country"
                              className="form-control"
                              id="Country"
                              value={country}
                              onChange={handleCountryChange}
                            >
                              <option value="">-- Select a country --</option>

                              <option value="UK">United Kingdom</option>
                              <option value="IR">Ireland</option>
                              <option value="EU">European Union</option>
                            </select>
                          </div>
                          {countryError && (
                            <span className="error">{countryError}</span>
                          )}
                        </div>

                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              name="terms"
                              type="checkbox"
                              defaultValue
                              id="acceptTerms"
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="acceptTerms"
                            >
                              I agree and accept the{" "}
                              <Link
                                to={Routes.TERMSANDCONDITION}
                                target="_blank"
                              >
                                terms and conditions
                              </Link>
                            </label>
                          </div>

                          {termsAcceptedError && (
                            <span className="error">{termsAcceptedError}</span>
                          )}
                        </div>
                        {error && (
                          <div className="col-xs-12 col-md-12 col-lg-12">
                            <div className="alert alert-danger">{error}</div>
                          </div>
                        )}
                        <div className="col-12 login mt-3 mb-3 text-center">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Create Account
                          </button>
                        </div>
                        <div className="col-12 text-center create-account">
                          <p className="small mb-0">
                            Already have an account?{" "}
                            <Link to={uriId ? uriId : "/login"}>Log in</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
};

export default injectModels(["auth"])(Register);
