import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { AdminService } from "../../services";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function PatientUpdatedForm() {
  const [skipPharmacyDetails, setSkipPharmacyDetails] = useState(false);
  const [skipGpDetails, setSkipGpDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const [gpName, setGpName] = useState("");
  const [surgeryName, setSurgeryName] = useState("");
  const [gpPhoneNumber, setGpPhoneNumber] = useState("");
  const [gpEmail, setGpEmail] = useState("");
  const [gpAddress1, setGpAddress1] = useState("");
  const [gpAddress2, setGpAddress2] = useState("");
  const [gpPostcode, setGpPostcode] = useState("");
  const [gpCity, setGpCity] = useState("");
  const [gpCountryCode, setGpCountryCode] = useState("");
  const [gpNameError, setGPNameError] = useState("");

  const [surgeryNameError, setSurgeryNameError] = useState("");
  const [gpPhoneNumberError, setGPPhoneNumberError] = useState("");
  const [gpEmailError, setGPEmailError] = useState("");
  const [gpAddress1Error, setGPAddress1Error] = useState("");
  const [gpAddress2Error, setGPAddress2Error] = useState("");
  const [gpPostcodeError, setGPPostcodeError] = useState("");
  const [gpCityError, setGPCityError] = useState("");
  const [gpCountryCodeError, setGPCountryCodeError] = useState("");

  const [pharmacyName, setPharmacyName] = useState("");
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState("");
  const [pharmacyEmail, setPharmacyEmail] = useState("");
  const [pharmacyAddress1, setPharmacyAddress1] = useState("");
  const [pharmacyAddress2, setPharmacyAddress2] = useState("");
  const [pharmacyPostcode, setPharmacyPostcode] = useState("");
  const [pharmacyCity, setPharmacyCity] = useState("");
  const [pharmacyCountryCode, setPharmacyCountryCode] = useState("");

  const [pharmacyNameError, setPharmacyNameError] = useState("");
  const [pharmacyPhoneNumberError, setPharmacyPhoneNumberError] = useState("");
  const [pharmacyEmailError, setPharmacyEmailError] = useState("");
  const [pharmacyAddress1Error, setPharmacyAddress1Error] = useState("");
  const [pharmacyAddress2Error, setPharmacyAddress2Error] = useState("");
  const [pharmacyPostcodeError, setPharmacyPostcodeError] = useState("");
  const [pharmacyCityError, setPharmacyCityError] = useState("");
  const [pharmacyCountryCodeError, setPharmacyCountryCodeError] = useState("");

  const [error, setError] = useState("");

  const history = useHistory();
  const pId = localStorage.getItem("patientId");

  const handleGoBack = () => {
    history.push({
      pathname: Routes.PATIENTFORM,
      state: true,
    });
  };

  const handleGpNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setGPNameError("");
    } else {
      setGPNameError("");
    }
    setGpName(val.trimStart());
  };

  const handleSurgeryNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setSurgeryNameError("Name is required");
    } else if (val.trim().length < 2) {
      setSurgeryNameError(
        "The surgery name field must be at least 2 characters."
      );
    } else {
      setSurgeryNameError("");
    }
    setSurgeryName(val.trimStart());
  };

  const handleGpPhoneNumberChange = (e) => {
    const regex = /^\+?[0-9]{8,15}$/;
    const val = e.target.value;
    const sanitizedVal = val.replace(/^[+0]+/, ""); // Remove leading plus sign or zeros

    if (val.trim() === "") {
      setGPPhoneNumberError("");
    } else if (!regex.test(sanitizedVal)) {
      setGPPhoneNumberError("Enter a valid phone number (8 to 15 digits)");
    } else {
      setGPPhoneNumberError("");
    }

    setGpPhoneNumber(val.trim());
  };

  const handleGpEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();

    if (val.trim() === "") {
      setGPEmailError("");
    } else if (!emailRegex.test(val)) {
      setGPEmailError("Enter a valid email");
    } else {
      setGPEmailError("");
    }

    setGpEmail(val);
  };

  const handleGpAddress1Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setGPAddress1Error("GP Address1 is required");
    } else if (val.trim().length < 2) {
      setGPAddress1Error("GP Address1 field must be at least 2 characters.");
    } else {
      setGPAddress1Error("");
    }
    setGpAddress1(val.trimStart());
  };

  const handleGpAddress2Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setGPAddress2Error("");
    } else if (val.trim().length < 2) {
      setGPAddress2Error("GP Address2 must be at least 2 characters");
    } else {
      setGPAddress2Error("");
    }
    setGpAddress2(val.trimStart());
  };

  const handleGpPostcodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const regex = /^[a-zA-Z0-9\s]{6,9}$/;

    if (val.trim() === "") {
      setGPPostcodeError("Gp Postcode is required");
    } else if (!regex.test(val)) {
      setGPPostcodeError(
        "Please enter a valid postcode (6 to 9 alphanumeric characters and spaces)"
      );
    } else {
      setGPPostcodeError("");
    }

    setGpPostcode(val);
  };

  const handleGpCityChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setGPCityError("GP city is required");
    } else if (val.trim().length < 2) {
      setGPCityError("GP city must be at least 2 characters");
    } else {
      setGPCityError("");
    }
    setGpCity(val.trimStart());
  };

  const handleGpCountryCodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setGPCountryCodeError("GP country is required");
    } else {
      setGPCountryCodeError("");
    }
    setGpCountryCode(val.trim());
  };

  const handlePharmacyNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPharmacyNameError("Pharmacy name is required");
    } else if (val.trim().length < 2) {
      setPharmacyNameError("Pharmacy Name must be at least 2 characters");
    } else {
      setPharmacyNameError("");
    }
    setPharmacyName(val.trimStart());
  };

  const handlePharmacyPhoneNumberChange = (e) => {
    const regex = /^\+?[0-9]{8,15}$/;
    const val = e.target.value;
    const sanitizedVal = val.replace(/^[+0]+/, ""); // Remove leading plus sign or zeros

    if (val.trim() === "") {
      setPharmacyPhoneNumberError("");
    } else if (!regex.test(sanitizedVal)) {
      setPharmacyPhoneNumberError(
        "Enter a valid phone number (8 to 15 digits)"
      );
    } else {
      setPharmacyPhoneNumberError("");
    }

    setPharmacyPhoneNumber(val.trim());
  };

  const handlePharmacyEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val.trim() === "") {
      setPharmacyEmailError("");
    } else if (!emailRegex.test(val)) {
      setPharmacyEmailError("Enter a valid email");
    } else {
      setPharmacyEmailError("");
    }

    setPharmacyEmail(val);
  };

  const handlePharmacyAddress1Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPharmacyAddress1Error("Pharmacy Address1 is required");
    } else if (val.trim().length < 2) {
      setPharmacyAddress1Error(
        "Pharmacy Address1 must be at least 2 characters"
      );
    } else {
      setPharmacyAddress1Error("");
    }
    setPharmacyAddress1(val.trimStart());
  };

  const handlePharmacyAddress2Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPharmacyAddress2Error("");
    } else if (val.trim().length < 2) {
      setPharmacyAddress2Error(
        "Pharmacy Address2 must be at least 2 characters"
      );
    } else {
      setPharmacyAddress2Error("");
    }
    setPharmacyAddress2(val.trimStart());
  };

  const handlePharmacyPostcodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const regex = /^[a-zA-Z0-9\s]{6,9}$/;

    if (val.trim() === "") {
      setPharmacyPostcodeError("Postcode is required");
    } else if (!regex.test(val)) {
      setPharmacyPostcodeError(
        "Please enter a valid postcode (6 to 9 alphanumeric characters and spaces)"
      );
    } else {
      setPharmacyPostcodeError("");
    }

    setPharmacyPostcode(val);
  };

  const handlePharmacyCityChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPharmacyCityError("Pharmacy city is required");
    } else if (val.trim().length < 2) {
      setPharmacyCityError("Pharmacy city must be at least 2 characters");
    } else {
      setPharmacyCityError("");
    }
    setPharmacyCity(val.trimStart());
  };

  const handlePharmacyCountryCodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setPharmacyCountryCodeError("Pharmacy countary is required");
    } else {
      setPharmacyCountryCodeError("");
    }
    setPharmacyCountryCode(val.trim());
  };

  const handleSubmit = async (e) => {
    const regex = /^[a-zA-Z0-9\s]{6,9}$/;
    e.preventDefault();
    let valid = true;

    if (!skipGpDetails && !skipPharmacyDetails) {
      if (surgeryName === "") {
        setSurgeryNameError("Surgery Name is required");
        valid = false;
      } else if (surgeryName.trim().length < 2) {
        setSurgeryNameError(
          "The surgery name field must be at least 2 characters."
        );
        valid = false;
      } else {
        setSurgeryNameError("");
      }

      if (gpAddress1 === "") {
        setGPAddress1Error("Address Line 1 is required");
        valid = false;
      } else if (gpAddress1.trim().length < 2) {
        setGPAddress1Error("GP Address1 field must be at least 2 characters.");
        valid = false;
      } else {
        setGPAddress1Error("");
      }

      if (gpCity === "") {
        setGPCityError("GP City is required");
        valid = false;
      } else if (gpCity.trim().length < 2) {
        setPharmacyCityError("Gp City field must be at least 2 characters.");
        valid = false;
      } else {
        setGPCityError("");
      }

      if (gpCountryCode === "") {
        setGPCountryCodeError("Country is required");
        valid = false;
      } else {
        setGPCountryCodeError("");
      }

      if (gpPostcode === "") {
        setGPPostcodeError("GP Postcode is required");
        valid = false;
      } else if (!regex.test(gpPostcode)) {
        setGPPostcodeError("Please enter a valid postcode");
        valid = false;
      } else {
        setGPPostcodeError("");
      }
      if (pharmacyName === "") {
        setPharmacyNameError("Pharmacy Name is required");
        valid = false;
      } else if (pharmacyName.trim().length < 2) {
        setPharmacyNameError(
          "Pharmacy Name field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyNameError("");
      }

      if (pharmacyAddress1 === "") {
        setPharmacyAddress1Error("Address Line 1 is required");
        valid = false;
      } else if (pharmacyAddress1.trim().length < 2) {
        setPharmacyCityError(
          "Pharmacy Address 1 field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyAddress1Error("");
      }

      if (pharmacyCity === "") {
        setPharmacyCityError("Pharmacy City is required");
        valid = false;
      } else if (pharmacyCity.trim().length < 2) {
        setPharmacyCityError(
          "Pharmacy City field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyCityError("");
      }

      if (pharmacyPostcode === "") {
        setPharmacyPostcodeError("Pharmacy Postcode is required");
        valid = false;
      } else if (!regex.test(pharmacyPostcode)) {
        setPharmacyPostcodeError("Please enter a valid postcode");
        valid = false;
      } else {
        setPharmacyPostcodeError("");
      }

      if (pharmacyCountryCode === "") {
        setPharmacyCountryCodeError("Pharmacy Country Code is required");
        valid = false;
      } else {
        setPharmacyCountryCodeError("");
      }
    }

    if (!skipGpDetails) {
      if (surgeryName === "") {
        setSurgeryNameError("Surgery Name is required");
        valid = false;
      } else if (surgeryName.trim().length < 2) {
        setSurgeryNameError(
          "The surgery name field must be at least 2 characters."
        );
        valid = false;
      } else {
        setSurgeryNameError("");
      }
      if (gpAddress1 === "") {
        setGPAddress1Error("Address Line 1 is required");
        valid = false;
      } else if (gpAddress1.trim().length < 2) {
        setGPAddress1Error("GP Address1 field must be at least 2 characters.");
        valid = false;
      } else {
        setGPAddress1Error("");
      }
      if (gpCity === "") {
        setGPCityError("City is required");
        valid = false;
      } else if (gpCity.trim().length < 2) {
        setPharmacyCityError("Gp City field must be at least 2 characters.");
        valid = false;
      } else {
        setGPCityError("");
      }
      if (gpPostcode === "") {
        setGPPostcodeError("Postcode is required");
        valid = false;
      } else if (!regex.test(gpPostcode)) {
        setGPPostcodeError("Please enter a valid postcode");
        valid = false;
      } else {
        setGPPostcodeError("");
      }
      if (gpCountryCode === "") {
        setGPCountryCodeError("Country Code is required");
        valid = false;
      } else {
        setGPCountryCodeError("");
      }
    }
    if (!skipPharmacyDetails) {
      if (pharmacyName === "") {
        setPharmacyNameError("Pharmacy Name is required");
        valid = false;
      } else if (pharmacyName.trim().length < 2) {
        setPharmacyNameError(
          "Pharmacy Name field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyNameError("");
      }

      if (pharmacyAddress1 === "") {
        setPharmacyAddress1Error("Address Line 1 is required");
        valid = false;
      } else if (pharmacyAddress1.trim().length < 2) {
        setPharmacyCityError(
          "Pharmacy Address 1 field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyAddress1Error("");
      }

      if (pharmacyCity === "") {
        setPharmacyCityError("Pharmacy City is required");
        valid = false;
      } else if (pharmacyCity.trim().length < 2) {
        setPharmacyCityError(
          "Pharmacy City field must be at least 2 characters."
        );
        valid = false;
      } else {
        setPharmacyCityError("");
      }

      if (pharmacyPostcode === "") {
        setPharmacyPostcodeError("Pharmacy Postcode is required");
        valid = false;
      } else if (!regex.test(pharmacyPostcode)) {
        setPharmacyPostcodeError("Please enter a valid postcode");
        valid = false;
      } else {
        setPharmacyPostcodeError("");
      }

      if (pharmacyCountryCode === "") {
        setPharmacyCountryCodeError("Pharmacy Country Code is required");
        valid = false;
      } else {
        setPharmacyCountryCodeError("");
      }
    }

    if (!valid) {
      return;
    } else {
      setError("");
      setLoading(true);
      const gpDetails = {
        patient_id: pId,
        gp_name: gpName && gpName ? gpName : "null",
        surgery_name: surgeryName && surgeryName,
        gp_phonenumber: gpPhoneNumber && gpPhoneNumber,
        gp_email: gpEmail && gpEmail,
        gp_address1: gpAddress1 && gpAddress1,
        gp_address2: gpAddress2 && gpAddress2,
        gp_postcode: gpPostcode && gpPostcode,
        gp_city: gpCity && gpCity,
        gp_countrycode: gpCountryCode && gpCountryCode,
      };

      const pharmacyDetails = {
        patient_id: pId,
        pharmacy_name: pharmacyName && pharmacyName,
        pharmacy_phonenumber: pharmacyPhoneNumber && pharmacyPhoneNumber,
        pharmacy_email: pharmacyEmail && pharmacyEmail,
        pharmacy_address1: pharmacyAddress1 && pharmacyAddress1,
        pharmacy_address2: pharmacyAddress2 && pharmacyAddress2,
        pharmacy_postcode: pharmacyPostcode && pharmacyPostcode,
        pharmacy_city: pharmacyCity && pharmacyCity,
        pharmacy_countrycode: pharmacyCountryCode && pharmacyCountryCode,
      };

      try {
        // if (!skipPharmacyDetails) {
        //   await AdminService.updatePatientGPDetails(pId, gpDetails);
        //   setLoading(false);
        //   setLoading(true);
        //   await AdminService.updatePatientPharmacyDetails(pId, pharmacyDetails);
        //   setLoading(false);
        // } else {
        //   await AdminService.updatePatientGPDetails(pId, gpDetails);
        //   setLoading(false);
        // }
        // setLoading(false);
        if (!skipGpDetails && !skipPharmacyDetails) {
          await AdminService.updatePatientGPDetails(pId, gpDetails);
          setLoading(false);
          await AdminService.updatePatientPharmacyDetails(pId, pharmacyDetails);
        } else if (!skipGpDetails) {
          await AdminService.updatePatientGPDetails(pId, gpDetails);
          setLoading(false);
        } else if (!skipPharmacyDetails) {
          await AdminService.updatePatientPharmacyDetails(pId, pharmacyDetails);
          setLoading(false);
        }
        setLoading(false);
        toast.success("Patient record updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });
        // Redirect to the patient details page

        history.push(`${Routes.BOOKING}/${pId}/0`);
      } catch (error) {
        // Handle error
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="patientform pst">
        <div className="container">
          <div className="row justify-content-center">
            <button className="back-button me-3" onClick={handleGoBack}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className="col-xl-7 col-lg-7 col-md-10 d-flex flex-column align-items-center justify-content-center">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-1 pb-3">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Update Patient Record
                    </h5>
                  </div>
                  <form
                    className="needs-validation account-login"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-check mt-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="skipCheckboxGp"
                        checked={skipGpDetails}
                        onChange={() => setSkipGpDetails(!skipGpDetails)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="skipCheckboxGp"
                      >
                        I do not have a registered GP
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="skipCheckboxPharmacy"
                        checked={skipPharmacyDetails}
                        onChange={() =>
                          setSkipPharmacyDetails(!skipPharmacyDetails)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="skipCheckboxPharmacy"
                      >
                        I do not have a registered Pharmacy
                      </label>
                    </div>
                    {!skipGpDetails && (
                      <>
                        <div className="row ">
                          <div className="gp-details">
                            <h3>GP Details</h3>
                          </div>
                          <div className=" col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="gpName" className="form-label">
                              GP Name
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-regular fa-user" />{" "}
                              </span>
                              <input
                                type="text"
                                name="gpName"
                                className="form-control"
                                id="gpName"
                                value={gpName}
                                onChange={handleGpNameChange}
                              />
                            </div>
                            {gpNameError && (
                              <span className="error">{gpNameError}</span>
                            )}
                          </div>
                          <div className=" col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="surgeryName" className="form-label">
                              Surgery Name
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-regular fa-user" />{" "}
                              </span>
                              <input
                                type="text"
                                name="surgery_name"
                                className="form-control"
                                id="surgeryName"
                                value={surgeryName}
                                onChange={handleSurgeryNameChange}
                              />
                            </div>
                            {surgeryNameError && (
                              <span className="error">{surgeryNameError}</span>
                            )}
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="email" className="form-label">
                              GP Email
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                type="text"
                                name="gp_email"
                                className="form-control"
                                id="gp_email"
                                value={gpEmail}
                                onChange={handleGpEmailChange}
                              />
                            </div>
                            {gpEmailError && (
                              <span className="error">{gpEmailError}</span>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="gp_phonenumber"
                              className="form-label"
                            >
                              GP Phone Number
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-mobile-screen-button" />
                              </span>
                              <input
                                type="text"
                                name="gp_phonenumber"
                                className="form-control"
                                id="gp_phonenumber"
                                value={gpPhoneNumber}
                                onChange={handleGpPhoneNumberChange}
                                placeholder="+44123456789"
                              />
                            </div>
                            {gpPhoneNumberError && (
                              <span className="error">
                                {gpPhoneNumberError}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="address1" className="form-label">
                              GP Address 1
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="address1"
                                className="form-control"
                                id="address1"
                                value={gpAddress1}
                                onChange={handleGpAddress1Change}
                              />
                            </div>
                            {gpAddress1Error && (
                              <span className="error">{gpAddress1Error}</span>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="address2" className="form-label">
                              GP Address 2
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="address2"
                                className="form-control"
                                id="address2"
                                value={gpAddress2}
                                onChange={handleGpAddress2Change}
                              />
                            </div>
                            {gpAddress2Error && (
                              <span className="error">{gpAddress2Error}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="gp_postcode" className="form-label">
                              GP Postcode/Eircode
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                type="text"
                                name="gp_postcode"
                                className="form-control"
                                id="gp_postcode"
                                value={gpPostcode}
                                onChange={handleGpPostcodeChange}
                              />
                            </div>
                            {gpPostcodeError && (
                              <span className="error">{gpPostcodeError}</span>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label htmlFor="gpCity" className="form-label">
                              GP City
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-globe" />
                              </span>
                              <input
                                type="text"
                                name="gp_city"
                                className="form-control"
                                id="gpCity"
                                value={gpCity}
                                onChange={handleGpCityChange}
                              />
                            </div>
                            {gpCityError && (
                              <span className="error">{gpCityError}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-12 mb-2">
                          <label htmlFor="Country" className="form-label">
                            GP Country
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-globe" />
                            </span>
                            <select
                              name="Country"
                              className="form-select"
                              id="Country"
                              value={gpCountryCode}
                              onChange={handleGpCountryCodeChange}
                            >
                              <option value="">-- Select a country --</option>
                              <option value="UK">United Kingdom</option>
                              <option value="IR">Ireland</option>
                            </select>
                          </div>
                          {gpCountryCodeError && (
                            <span className="error">{gpCountryCodeError}</span>
                          )}
                        </div>
                      </>
                    )}

                    {!skipPharmacyDetails && (
                      <>
                        <div className="row">
                          <div className="gp-details">
                            <h3>Pharmacy Details</h3>
                          </div>
                          <div className=" col-md-6 col-12 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacy_name"
                              className="form-label"
                            >
                              Pharmacy Name
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-regular fa-user" />{" "}
                              </span>
                              <input
                                type="text"
                                name="pharmacy_name"
                                className="form-control"
                                id="pharmacy_name"
                                value={pharmacyName}
                                onChange={handlePharmacyNameChange}
                              />
                            </div>
                            {pharmacyNameError && (
                              <span className="error">{pharmacyNameError}</span>
                            )}
                          </div>

                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacyPhoneNumber"
                              className="form-label"
                            >
                              Pharmacy Phone Number
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-mobile-screen-button" />
                              </span>
                              <input
                                type="text"
                                name="pharmacyPhoneNumber"
                                className="form-control"
                                id="pharmacyPhoneNumber"
                                value={pharmacyPhoneNumber}
                                onChange={handlePharmacyPhoneNumberChange}
                                placeholder="+44123456789"
                              />
                            </div>
                            {pharmacyPhoneNumberError && (
                              <span className="error">
                                {pharmacyPhoneNumberError}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacyEmail"
                              className="form-label"
                            >
                              Pharmacy Email
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                type="text"
                                name="pharmacyEmail"
                                className="form-control"
                                id="pharmacyEmail"
                                value={pharmacyEmail}
                                onChange={handlePharmacyEmailChange}
                              />
                            </div>
                            {pharmacyEmailError && (
                              <span className="error">
                                {pharmacyEmailError}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacy_address1"
                              className="form-label"
                            >
                              Pharmacy Address Line 1
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="pharmacy_address1"
                                className="form-control"
                                id="pharmacy_address1"
                                value={pharmacyAddress1}
                                onChange={handlePharmacyAddress1Change}
                              />
                            </div>
                            {pharmacyAddress1Error && (
                              <span className="error">
                                {pharmacyAddress1Error}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacyAddress2"
                              className="form-label"
                            >
                              Pharmacy Address Line 2
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="pharmacyAddress2"
                                className="form-control"
                                id="pharmacyAddress2"
                                value={pharmacyAddress2}
                                onChange={handlePharmacyAddress2Change}
                              />
                            </div>
                            {pharmacyAddress2Error && (
                              <span className="error">
                                {pharmacyAddress2Error}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacy_postcode"
                              className="form-label"
                            >
                              Pharmacy Postcode/Eircode
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                type="text"
                                name="pharmacy_postcode"
                                className="form-control"
                                id="pharmacy_postcode"
                                value={pharmacyPostcode}
                                onChange={handlePharmacyPostcodeChange}
                              />
                            </div>
                            {pharmacyPostcodeError && (
                              <span className="error">
                                {pharmacyPostcodeError}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-xxl-2 mb-lg-2 mb-md-2 mb-2">
                            <label
                              htmlFor="pharmacyCity"
                              className="form-label"
                            >
                              Pharmacy City
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-globe" />
                              </span>
                              <input
                                type="text"
                                name="pharmacyCity"
                                className="form-control"
                                id="pharmacyCity"
                                value={pharmacyCity}
                                onChange={handlePharmacyCityChange}
                              />
                            </div>
                            {pharmacyCityError && (
                              <span className="error">{pharmacyCityError}</span>
                            )}
                          </div>

                          <div className="mb-3 col-md-6 col-12">
                            <label
                              htmlFor="pharmacy_countrycode"
                              className="form-label"
                            >
                              Pharmacy Country
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-globe" />
                              </span>
                              <select
                                name="pharmacy_countrycode"
                                className="form-select"
                                id="pharmacy_countrycode"
                                value={pharmacyCountryCode}
                                onChange={handlePharmacyCountryCodeChange}
                              >
                                <option value="">-- Select a country --</option>
                                <option value="UK">United Kingdom</option>
                                <option value="IR">Ireland</option>
                              </select>
                            </div>
                            {pharmacyCountryCodeError && (
                              <span className="error">
                                {pharmacyCountryCodeError}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {error && (
                      <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="alert alert-danger">{error}</div>
                      </div>
                    )}

                    <div className="submit-btn-pa">
                      <button>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PatientUpdatedForm;
