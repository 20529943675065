import React, { useState, useEffect } from "react";
import { AdminService } from "../../services";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constants";

const BookingForm = (props) => {
  const [bookingReason, setBookinReason] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const [sameAddress, setSameAddress] = useState(true);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [contactError, setContactError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [address2Error, setAddress2Error] = useState("");

  const history = useHistory();
  const userId = localStorage.getItem("user_id");
  const patient_id = localStorage.getItem("patientId");
  const dep_Id = localStorage.getItem("dependent_Id");


  const handleSameAddressChange = (event) => {
    setSameAddress(event.target.checked);
  };

  const handleAddress1Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAddress1Error("");
    } else if (val.trim().length < 2) {
      setAddress1Error("Address 1 must be at least 2 characters");
    } else {
      setAddress1Error("");
    }
    setAddress1(val.trimStart());
  };

  const handleAddress2Change = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAddress2Error("");
    } else if (val.trim().length < 2) {
      setAddress2Error("Address 2 must be at least 2 characters");
    } else {
      setAddress2Error("");
    }
    setAddress2(val.trimStart());
  };

  const handleCityChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setCityError("");
    } else if (val.trim().length < 2) {
      setCityError("City must be at least 2 characters");
    } else {
      setCityError("");
    }
    setCity(val);
  };

  const handleCountryChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length === 17) return false;
    if (val.trim() === "") {
      setCountryError("");
    } else {
      setCountryError("");
    }
    setCountry(val.trim());
  };
  const handleContactChange = (e) => {
    const regex = /^\+?[0-9]{8,15}$/;
    const val = e.target.value;
    const sanitizedVal = val.replace(/^[+0]+/, ""); // Remove leading plus sign or zeros

    if (val.trim() === "") {
      setContactError("");
    } else if (!regex.test(sanitizedVal)) {
      setContactError("Enter a valid phone number (8 to 15 digits)");
    } else {
      setContactError("");
    }

    setContactNo(val.trim());
  };

  const handlePostcodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const regex = /^[a-zA-Z0-9\s]{6,9}$/;

    if (val.trim() === "") {
      setPostcodeError("");
    } else if (!regex.test(val)) {
      setPostcodeError(
        "Please enter a valid postcode (6 to 9 alphanumeric characters and spaces)"
      );
    } else {
      setPostcodeError("");
    }

    setPostcode(val);
  };

  const getUserDetails = async () => {
    const response = await AdminService.getPatientDetails(patient_id);
    setUserDetails(response && response);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const handleBookingResonChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setError("Reason for booking is required");
    } else {
      setError("");
    }
    setBookinReason(val.trimStart());
  };
  const bookingData = props.location && props.location.state;

  const handleBookingSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    if (bookingReason === "") {
      setError("Reason for booking is required");
      valid = false;
    } else {
      setError("");
    }
    if (!valid) {
      setLoading(false);
      return;
    } else {
      setLoading(true);

      if (sameAddress) {
        const dep_Id = localStorage.getItem("dependent_Id");
        const dependent_Id = dep_Id ? dep_Id : "0";
        const data = {
          reason_for_booking: bookingReason,
          phone_number:
            userDetails.message.contactno && userDetails.message.contactno,
          address: userDetails.message.address1 && userDetails.message.address1,
          address2:
            userDetails.message.address2 && userDetails.message.address2,
          post_code:
            userDetails.message.post_code && userDetails.message.post_code,
          city: userDetails.message.city && userDetails.message.city,
          country: userDetails.message.country && userDetails.message.country,
          gender_type: bookingData.gender ? bookingData.gender : "Male",
          specialties_type:
            bookingData.item && bookingData.item.attributes.specialties[0],
          consultation_type:
            bookingData && bookingData.selectedConsultationType,
          appointment_id: bookingData.item && bookingData.item.id,
          appointment_time:
            bookingData.item && bookingData.item.attributes.start,
          uid: userId,
          dependent_Id: dependent_Id,
        };

        try {
          const response = await AdminService.createBooking(data, patient_id);
          if (response.status === false) {
            toast.error(response.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          } else {
            toast.success("Booking created Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
            localStorage.removeItem("patientId");
            localStorage.removeItem("patientCountry");
            localStorage.removeItem("dependent_Id");
            history.push(Routes.DASHBOARD);
          }
        } catch (err) {
          setLoading(false);
          console.log(err, "error at create booking");
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        let valid = true;
        if (contactNo === "") {
          setContactError(
            "Contact Number is required if patient is not available at the address mentioned earlier at the time of appointment"
          );
          valid = false;
        } else {
          setContactError("");
        }
        if (address1 === "") {
          setAddress1Error(
            "Address is required if patient is not available at the address mentioned earlier at the time of appointment"
          );
          valid = false;
        } else if (address1.trim().length < 2) {
          setAddress1Error("Address 1 must be at least 2 characters");
          valid = false;
        } else {
          setAddress1Error("");
        }
        const regex = /^[a-zA-Z0-9\s]{6,9}$/;
        if (postcode === "") {
          setPostcodeError(
            "Postcode is required if patient is not available at the address mentioned earlier at the time of appointment"
          );
          valid = false;
        } else if (!regex.test(postcode)) {
          setPostcodeError(
            "Please enter a valid postcode (6 to 9 alphanumeric characters and spaces)"
          );
          valid = false;
        } else {
          setPostcodeError("");
        }
        if (city === "") {
          setCityError(
            "City is required if patient is not available at the address mentioned earlier at the time of appointment"
          );
          valid = false;
        } else if (city.trim().length < 2) {
          setCityError("City must be at least 2 characters");
          valid = false;
        } else {
          setCityError("");
        }
        if (country === "") {
          setCountryError(
            "Country is required if patient is not available at the address mentioned earlier at the time of appointment"
          );
          valid = false;
        } else {
          setCountryError("");
        }

        if (!valid) {
          setLoading(false);
          return;
        } else {
          setLoading(true);
          const dep_Id = localStorage.getItem("dependent_Id");
          const dependent_Id = dep_Id ? dep_Id : "0";
          const data = {
            reason_for_booking: bookingReason,
            phone_number: contactNo,
            address: address1,
            address2: address2,
            post_code: postcode,
            city: city,
            country: country,
            gender_type: bookingData.gender ? bookingData.gender : "Male",
            specialties_type:
              bookingData.item && bookingData.item.attributes.specialties[0],
            consultation_type:
              bookingData && bookingData.selectedConsultationType,
            appointment_id: bookingData.item && bookingData.item.id,
            appointment_time:
              bookingData.item && bookingData.item.attributes.start,
            uid: userId,
            dependent_Id: dependent_Id,
          };

          try {
            const response = await AdminService.createBooking(data, patient_id);
            if (response.status === false) {
              toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoading(false);
            } else {
              toast.success("Booking created Successfully!", {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoading(false);
              localStorage.removeItem("patientId");
              localStorage.removeItem("patientCountry");
              history.push(Routes.DASHBOARD);
            }
          } catch (err) {
            setLoading(false);
            console.log(err, "error at create booking");
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      }
    }
  };
  const dependent_Id = dep_Id ? dep_Id : "0"

  const handleGoBack = () => {
    history.push({
      pathname: Routes.BOOKING + "/" + patient_id + "/" +dependent_Id
      ,
      state: true,
    });
  };

  const date = new Date(bookingData.item && bookingData.item.attributes.start);

  const options3 = {
    month: "long",
    timeZone: "Europe/London",
  };
  const options2 = {
    day: "numeric",
    timeZone: "Europe/London",
  };
  const monthName = date.toLocaleDateString("en-US", options3);
  const dayName = date.toLocaleDateString("en-US", options2);
  const options1 = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Europe/London",
  };

  const time = date.toLocaleTimeString("en-GB", options1);

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="booking-filter b-reason">
        <div className="container">
          <div className="row apptB-result">
            <div
              className="col-md-12 d-flex flt"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <button className="back-button me-3" onClick={handleGoBack}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <h2 className="uph2 xxl-mb-4 lg-mb-4 md-mb-4 mb-0  pagetitle">
                Create Booking
              </h2>
            </div>

            <div className="col-md-2 mb-3">
              <div className="create-upc-date">
                <span>{monthName}</span>
                <h3>{dayName}</h3>
                <strong>{time}</strong>
              </div>
            </div>

            <div className="col-md-7 ">
              <div className="card n-card  reason-booking">
                <h5 className="reason">Reason for booking</h5>
                <div className="card-body">
                  <form
                    className="needs-validation account-login"
                    onSubmit={handleBookingSubmit}
                  >
                    <div className=" patient-text mb-3">
                      <textarea
                        onChange={handleBookingResonChange}
                        className="form-control"
                        rows="5"
						maxLength={150}
                      ></textarea>
                    </div>

                    {error && <span className="error">{error}</span>}
                    <h5 className="reason">Location at the time of the online consultation</h5>

                    <div className="check-input-pa">
                      <input
                        type="checkbox"
                        id="sameAddressCheckbox"
                        checked={sameAddress}
                        onChange={handleSameAddressChange}
                      />
                      <label htmlFor="sameAddressCheckbox">
                        Same as patient record
                      </label>
                    </div>

                    {!sameAddress && (
                      <React.Fragment>
                        <div className="row ">
                          <div className=" col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                            <label htmlFor="address1" className="form-label">
                              Address 1
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="address1"
                                className="form-control"
                                id="address1"
                                value={address1}
                                onChange={handleAddress1Change}
                              />
                            </div>
                            {address1Error && (
                              <span className="error">{address1Error}</span>
                            )}
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                            <label htmlFor="address2" className="form-label">
                              Address 2
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-map-marker-alt" />
                              </span>
                              <input
                                type="text"
                                name="address2"
                                className="form-control"
                                id="address2"
                                value={address2}
                                onChange={handleAddress2Change}
                              />
                            </div>
                            {address2Error && (
                              <span className="error">{address2Error}</span>
                            )}
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-building" />
                              </span>
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                id="city"
                                value={city}
                                onChange={handleCityChange}
                              />
                            </div>
                            {cityError && (
                              <span className="error">{cityError}</span>
                            )}
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                            <label htmlFor="postcode" className="form-label">
                              Postcode
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-envelope" />
                              </span>
                              <input
                                type="text"
                                name="postcode"
                                className="form-control"
                                id="postcode"
                                value={postcode}
                                onChange={handlePostcodeChange}
                              />
                            </div>
                            {postcodeError && (
                              <span className="error">{postcodeError}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                            <label htmlFor="Country" className="form-label">
                              Country
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-globe" />
                              </span>
                              <select
                                name="Country"
                                className="form-select"
                                id="Country"
                                value={country}
                                onChange={handleCountryChange}
                              >
                                <option value="">-- Select a country --</option>
                                <option value="UK">United Kingdom</option>
                                <option value="IR">Ireland</option>
                              </select>
                            </div>
                            {countryError && (
                              <span className="error">{countryError}</span>
                            )}
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12 mb-2">
                            <label htmlFor="contact" className="form-label">
                              Contact Number
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa-solid fa-mobile-screen-button" />
                              </span>
                              <input
                                type="text"
                                name="contact"
                                className="form-control"
                                id="contact"
                                value={contactNo}
                                onChange={handleContactChange}
                                placeholder="+44123456789"
                              />
                            </div>
                            {contactError && (
                              <span className="error">{contactError}</span>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="col-md-12 login-patient">
                      <button className="btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card n-card p-3 date-time mb-0 brdr-blue-left ppt-status">
                <ul>
                  <li className="appt-time pt-0"></li>
                  <li>
                    <span>Specialties :</span>{" "}
                    <strong>
                      {" "}
                      {/* {bookingData.item &&
                        bookingData.item.attributes.specialties[0]} */}
                      General Practitioner
                    </strong>
                  </li>
                  {bookingData &&
                  bookingData.selectedConsultationType === "" ? (
                    <li></li>
                  ) : (
                    <li>
                      <span>Consultation Type :</span>{" "}
                      <strong>
                        {" "}
                        {bookingData &&
                          bookingData.selectedConsultationType
                            .charAt(0)
                            .toUpperCase() +
                            bookingData.selectedConsultationType.slice(1)}
                      </strong>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BookingForm;
