import React, { useEffect } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import { DashboardLayout } from "./layouts";
import { Routes } from "./constants";
import { injectModels } from "./redux/injectModels";

const CustomAdminRoute = ({ component: Component, ...rest }, props) => {
  const history = useHistory();
  const isSubs = localStorage.getItem("Subscription_status");
  const istrial = localStorage.getItem("trial");

  // const sessionEndTime = localStorage.getItem("session_logOutTime");
  // const endTime = new Date(sessionEndTime);

  // useEffect(() => {
  //   const currentTime = new Date();
  //   const remainingTime = endTime - currentTime;

  //   console.log(currentTime,"currentTime");
  //   console.log(remainingTime,"remaining")
  //   const logoutTimer = setTimeout(() => {
  //     rest.auth.logout();
  //     localStorage.clear();
  //     history.push(Routes.LOGIN);

  //     console.log("User has been automatically logged out.");
  //   }, remainingTime);

  //   return () => clearTimeout(logoutTimer);
  // }, [endTime, rest.auth, history.push]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {isSubs == 1 || istrial == 1 ? (
              <DashboardLayout>
                <Component {...props} />
              </DashboardLayout>
            ) : (
              <Redirect to={Routes.PRODUCTSUBSCRIPTION} />
            )}
            {/* <DashboardLayout><Component {...props} /></DashboardLayout> */}
          </React.Fragment>
        );
      }}
    />
  );
};

export default injectModels(["auth"])(CustomAdminRoute);
