import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./../App.css";
import { Routes } from "../constants";
import { AdminService } from "../services";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { JWT } from "../shared";
import { injectModels } from "../redux/injectModels";

const CheckoutForm = (props) => {
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const isValidToken = JWT.isValidToken();

  const userId = localStorage.getItem("user_id");

  const secretData = {
    amount: 9.99,
    currency: "gbp",
  };

  const getUserDetails = async () => {
    const response = await AdminService.getUserDetails(userId);
    setUserDetails(response);
  };

  const getClientSecret = async () => {
    const response = await AdminService.getClientSecret(secretData);
    setClientSecret(response.clientSecret);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("Error:", error);
    } else {
      setLoading(true);
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: userDetails.name,
          email: userDetails.email,
        },
      },
    });

    const data = {
      paymentMethodId: paymentMethod.id ? paymentMethod.id : "",
      email: userDetails.email,
      amount: 9.99,
    };

    if (result.error) {
      console.error("Error confirming card payment:", result.error.message);
      setLoading(false);
      toast.error("Something Went Wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setLoading(false);
      toast.success("Thanks For Subscribing", {
        position: toast.POSITION.TOP_CENTER,
      });

      localStorage.setItem("Subscription_status", 1);
      history.push(Routes.THANKYOUPAGE);
    }
  };

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handlecoupounApplied = async (event) => {
    event.preventDefault();
    let valid = true;
    if (couponCode === "") {
      setCouponError("Please enter a Coupoun code");
      valid = false;
    } else {
      setCouponError("");
    }
    if (!valid) {
      return;
    } else {
      const data = {
        code: couponCode,
      };

      try {
        const response = await AdminService.getCoupounDetails(data);

        if (response.status === false) {
          setCouponError(response.message);
          setCouponSuccess("");
        } else if (
          response.status === "Coupon Code has applied successfully."
        ) {
          setCouponSuccess(response.status);
          setCouponError("");
        } else {
          console.log(response.status);
        }
      } catch (err) {}
    }
  };

  useEffect(async () => {
    getClientSecret();
  }, []);

  useEffect(async () => {
    getUserDetails();
  }, []);

  return (
    <React.Fragment>
      {loading && (
        <div className="spinner">
          <div className="loader">
            {" "}
            <Circles
              height="110"
              width="110"
              color="#FFF"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <section className="premium-checkout">
        <h2>Checkout</h2>
        <div className="container">
          <div className="row ">
            <div className="col-md-7">
              <div className="heckout-right">
                {isValidToken ? (
                  <React.Fragment>
                    <div className="payment-login">
                      <h5 className="pt-0 pb-3">
                        {" "}
                        <i className="fa-solid fa-check"></i> Sign Up
                      </h5>
                    </div>
                    <div className="signup-checkout">
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          props.auth.logout();
                          history.push(Routes.PRODUCTSUBSCRIPTION);
                          localStorage.clear();
                        }}
                        className="dropdown-item d-flex align-items-center"
                      >
                        <i className="fa-solid fa-arrow-right-from-bracket" />
                        <span>Logout</span>
                      </Link>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="payment-login">
                      <h5 className="pt-0 pb-3">1. Sign Up</h5>
                      <p>
                        To purchase this plan and use its benefits in the
                        future, log in to your account or sign up.
                      </p>
                    </div>
                    <div className="signup-checkout">
                      <Link to={Routes.REGISTER}>Sign Up</Link>
                      <Link to={Routes.LOGIN}>Login</Link>
                    </div>
                  </React.Fragment>
                )}

                <h5>2 &nbsp; Payment</h5>
                {isValidToken && (
                  <React.Fragment>
                    <div className="card">
                      <form onSubmit={handleSubmit} className="payment-form">
                        <div className="col-xxl-6 col-xl-5 col-md-6 mb-4 mb-md-0 ftr-new">
                          <div className="card card-payment">
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "#424770",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />{" "}
                            <div className="buy-now-btn">
                              <button>Buy Now</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="order-summary">
                <h6>Order summary</h6>
                <div className="membership-plan-checkout">
                  <p>Plan</p>
                  <p>Members subscription</p>
                </div>
                <div className="membership-plan-checkout">
                  <p>Duration</p>
                  <p>Until canceled</p>
                </div>
                <div className="coupon-code">
                  <div className="coupn-input-value">
                    <label className="mb-2">Enter a Coupon Code:</label>
                    <div className="apply-coupn">
                      <input
                        type="text"
                        placeholder="Enter a Coupon Code"
                        value={couponCode}
                        onChange={handleCouponCodeChange}
                      />
                      <button onClick={handlecoupounApplied}>Apply</button>
                    </div>
                  </div>

                  {/* Display the applied coupon code */}
                  {couponError && (
                    <div className="error">
                      <strong>{couponError}</strong>
                    </div>
                  )}
                  {couponSuccess && (
                    <div className="success">
                      <strong>{`${couponSuccess} `}</strong>
                    </div>
                  )}
                </div>

                <div className="membership-plan-checkout mt-3">
                  <p>Subtotal</p>
                  <p>€9.99</p>
                </div>
                <div className="membership-plan-checkout value-money">
                  <p>VAT (23 %)</p>
                  <p>€2.30</p>
                </div>
                <div className="total-value">
                  <p>Total</p>
                  <p>€12.29</p>
                </div>
                <span>every month</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default injectModels(["auth"])(CheckoutForm);
