import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from "./constants";
import { AuthService } from "../../../services";
import { JWT } from "../../../shared";
import axios from "axios";
export const login = (data) => async (dispatch, getState) => {
  try {
    const response = await AuthService.Login(data);
    if (response.success) {
      dispatch({ type: LOGIN_SUCCESS, payload: response.data.subscription });
      JWT.setJwt(response.data.subscription);
      return response;
    } else {
      dispatch({ type: LOGIN_FAILED, payload: null });
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: LOGIN_FAILED, payload: null });
    return Promise.reject(error);
  }
};

export const logout = () => (dispatch, getState) => {
  JWT.removeJWT();
  dispatch({ type: LOGOUT });
};

export const getOrgById = (uri, tokenData,referrerUrl) => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/organisations1?uri=${uri}&tokenVal=${tokenData}&referrerUrl=${referrerUrl}`
    );
    // console.log(response,"response")
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(new Error("invalid uri"));
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUpcomingBookingDetails =
  (user_id) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/booking/details/user/${user_id}`
      );
      if (response.status) {
        return response.data;
      } else {
        return Promise.reject(new Error("invalid uri"));
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };
