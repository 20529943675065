import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

const DashboardLayout = (props) => {
  return (
    <React.Fragment>
      <Header />
      <main id="main" className="main">
        <div className="tab-content" id="myTabContent">
          <div>{props.children}</div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default DashboardLayout;
